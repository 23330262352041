//Imports
import { 
    useState,
    useEffect 
} from 'react';

/**
 * Daily Stream privacy
 * policy Linking section
 * component
 */
const Linking = () => {
    //States

    //Helpers

    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
    }, []);

    return(
        <>
        <h1>Links</h1>
        <p>
            Our Platform may contain links to other sites. Please be aware that 
            We are not responsible for the content or privacy practices of such 
            other sites. We encourage our users to be aware when they leave our site 
            and to read the privacy statements of any other site that 
            collects personally identifiable information.
        </p>
        </>
    );
}

//Exports
export default Linking;
