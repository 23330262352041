//Imports
import React, { 
  useState, 
  useEffect,
} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Integrations from './Integrations';
import scrubbingGif from '../../Assets/video/scrubbing.mp4';
import '../../Assets/css/modal.css';

/**
 * Daily Stream hero
 * section component
 * for home page
 */
const HeroSection = () => {
    //States
    const [showModal, setShowModal] = useState(false);
    const [modal, setModal] = useState({
      title: "", 
      body: "", 
      submit: false, 
      submitText: "", 
      submitEvent: null, 
      close: false, 
      closeText: ""
    });

    //Helpers
    /**
     * Handles opening and closing modal
     */
    const handleShowModal = () => setShowModal(!showModal);

    /**
     * Opens calendly info
     * in modal window
     */
    const openCalendly = () => {
      setModal({
        title: "Schedule a Demo!",
        body: <iframe src='https://calendly.com/ryandailystream/consultation' style={{"width": "100%", "height": "100%"}}></iframe>,
        submit: false,
        close: false
      });
      handleShowModal();
    }

    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
    }, []);

    return(
        <>
          {/* Modal Window */}
          <Modal show={showModal} onHide={handleShowModal} dialogClassName="calendly-modal" contentClassName="expanded">
              <Modal.Header closeButton>
              <Modal.Title>{modal.title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{modal.body}</Modal.Body>
              <Modal.Footer>
              { modal.submit ? 
                  <Button variant="primary" onClick={modal.submitEvent ? modal.submitEvent : handleShowModal}>{modal.submitText}</Button>
              :
                  null
              }
              { modal.close ? 
                <Button variant="danger" onClick={handleShowModal}>{modal.closeText}</Button>
              :
                null
              }
              </Modal.Footer>
          </Modal>

          {/* Hero Section */}
          <section id="hero-section" className="m-8"> 
          <div className="row align-items-center">
            <div className="col-12 col-lg-5 col-lg-6 order-lg-1 mb-8 mb-lg-0">
              <video autoPlay muted loop>
                <source src={scrubbingGif} type="video/mp4"/>
              </video>
            </div>
            <div className="col-12 col-lg-7 col-xl-6">
              <h1 className="display-4" style={{"textAlign": "center"}}>
                Visibility For Remote Leaders</h1> 
              <p className="lead text-muted" style={{"textAlign": "center"}}>
                Quickly confirm (and easily correct) your remote productivity concerns with continuous screen and audio streams.
              </p>
              <a className="btn btn-primary shadow me-1 centered pt-2" onClick={openCalendly}>
                SCHEDULE DEMO
              </a>
              <p className="lead text-muted pt-2" style={{"textAlign": "center"}}>Integrates with</p>
              <Integrations/>
            </div>
          </div>
          </section>
        </>
    );
}

//Exports
export default HeroSection;
