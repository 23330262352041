//Imports
import { 
    useState,
    useEffect 
} from 'react';

/**
 * Daily Stream privacy
 * policy contact section
 * component
 */
const Contacting = () => {
    //States

    //Helpers

    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
    }, []);

    return(
        <>
        <h1>Contact Us</h1>
        <p>
            If there are any questions regarding this privacy policy you may contact us via email at <u>support@dailystream.com</u> or mail to 18 E Main St, Rapid City, SD 57701. 
        </p>
        </>
    );
}

//Exports
export default Contacting;
