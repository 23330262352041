//Imports
import React, {
    useEffect,
    useState
} from 'react';
import quality from '../../Assets/images/transformations/quality.jpg';
import SmallLoader from '../../Fragments/SmallLoader';

/**
 * Daily Stream quality
 * section for quality
 * management page
 */
const QualitySection = () => {
    //States
    const [imageLoading, setImageLoading] = useState(true);
    
    //Helpers
    /**
     * Sets image loading
     * state
     */
    const imageLoaded = () => setImageLoading(!imageLoading);

    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
    }, []);

    return(
        <>
            <section id="quality-management" className="m-8">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-12 d-flex justify-content-center">
                        <h1><span className="primary-text">Quality</span>
                            <span className="text-primary"> Management</span>
                        </h1>
                    </div>
                    <div className="col-md-6 d-flex justify-content-start align-content-center">
                        <img 
                            src={quality} 
                            style={{borderRadius: "5%", maxWidth: "75%", maxHeight: "100%"}}
                            onLoad={imageLoaded}
                            alt="Quality Management"
                        />
                        {imageLoading && <SmallLoader/>}
                    </div>
                    <div className="col-md-6 d-flex flex-wrap">
                    <h4>WFH and omnichannel have revolutionized contact centers. How has your QM evolved? Are you measuring and monitoring inactive status? Are you scoring off-call work? Does your omnichannel scoring include HD video prior to, during and after the interaction? Could all your QM be consolidated for speed and efficiency? Specific use cases and benefits include:</h4>
                    <ul>
                        <li>KPI Verification.</li>
                        <li>Off Call Productivity</li>
                        <li>New Hire Monitoring</li>
                        <li>Omnichannel Scoring</li>
                        <li>Inactive Status Measuring and Monitoring</li>
                        <li>Trust but Verify</li>
                        <li>Root Cause Analysis! There's no better way.</li>
                    </ul> 
                    </div>
                    </div>
                </div>
            </section>
        </>
    );
}

//Exports
export default QualitySection;
