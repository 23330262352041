//Imports
import { 
    useState,
    useEffect 
} from 'react';

/**
 * Daily Stream privacy
 * policy changes section
 * component
 */
const Changes = () => {
    //States

    //Helpers

    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
    }, []);

    return(
        <>
        <h1>Changes to Our Privacy Policy</h1>
        <p>It is our policy to post any changes We make to our privacy policy on this page.</p>
        </>
    );
}

//Exports
export default Changes;
