//Imports
import { 
    useState,
    useEffect 
} from 'react';

/**
 * Daily Stream privacy
 * policy social security
 * number section component
 */
const SSNProtection = () => {
    //States

    //Helpers

    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
    }, []);

    return(
        <>
        <h1>Social Security Number Protection Policy</h1>
        <p>
            Social Security numbers may only be accessed by and disclosed to 
            Our employees, contractors, agents and others with a legitimate 
            business purpose in accordance with applicable laws and regulations. 
            Social Security numbers, whether in paper or electronic form, are 
            subject to physical, electronic, and procedural safeguards. 
            These restrictions apply to all Social Security numbers collected or 
            retained by Us in connection with the use of Our site.
        </p>
        </>
    );
}

//Exports
export default SSNProtection;
