//Imports
import React, { 
  useEffect
} from 'react';

/**
 * Daily Stream loader
 * for main site
 */
const Loader = () => {
    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
    }, []);

    return(
        <div id="ht-preloader">
            <div className="loader clear-loader">
            <span></span>
            <p>Daily Stream</p>
            </div>
        </div>
    );
}

export default Loader;
