//Imports
import React, { 
  useState, 
  useEffect,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import '../../Assets/css/modal.css';
import { useParams, useSearchParams } from 'react-router-dom';
import { setCookie, getCookie } from '../../Hooks/Cookie';
import PasswordChecklist from 'react-password-checklist';

//Icon Styles
const styles = {
  colors: {
    primary: {
      color: "#007bff"
    }
  },
  hidden: {
    display: 'none'
  },
  visible: {
    display: 'table-cell'
  },
  font: {
    fontFamily: 'Roboto, Helvetica, sans-serif'
  },
  table: {
    maxHeight: '400px !important'
  },
  card: {
    header: {
      backgroundColor: '#727cf5',
      color: '#fff'
    }
  },
  icons: {
    connected: {
      color: '#10b759',
    },
    disconnected: {
      color: '#ff3366'
    },
    save: {
      color: '#10b759',
      fontSize: '1.2rem',
      cursor: 'pointer',
      marginRight: '10px'
    },
    cancel: {
      color: '#fbbc06',
      fontSize: '1.2rem',
      cursor: 'pointer'
    },
    edit: {
      color: '#007bff',
      fontSize: '1.2rem',
      cursor: 'pointer'
    },
    danger: {
      color: '#ff3366',
      fontSize: '1.2rem',
      cursor: 'pointer'
    }
  },
  alignMiddle: {
    alignItems: "center"
  },
  spinner: {
    height: "5rem",
    width: "5rem"
  }
}

/**
 * Daily Stream password
 * reset form for
 * password reset page
 */
const ResetForm = () => {
    //Route parameters
    const { id, otp } = useParams();

    //States
    const [queryParams, setQueryParams] = useSearchParams();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [email, setEmail] = useState("");
    const [modal, setModal] = useState({
        title: "", 
        body: "", 
        submit: false, 
        submitText: "", 
        submitEvent: null, 
        close: false, 
        closeText: "",
    });
    const [showModal, setShowModal] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [meetsRequirements, setMeetsRequirements] = useState(0);

    //Query parameters
    const code = queryParams.get("code");

    //Helpers
    /**
     * Handles opening and closing alert modal
     */
    const handleShowModal = () => setShowModal(!showModal);

    /**
     * Resets one time password
     */
    const resetOTP = (e) => {
        e.preventDefault();

        //Password check
        if (meetsRequirements) {
            //Reset One Time Password
            let data = {
                otp: otp,
                userId: id,
                password: password,
                confirmPassword: confirmPassword
            }
            
            fetch(`${window.apiSite}/resetOTP`, {
              method: `POST`,
              credentials: `include`,
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data)
            })
            .then(res => res.json())
            .then(json => onResetPassword(json))
        } else {
            setModal({
                title: "Error",
                body: "Your password does not meet the requirements!",
                submit: false,
                close: false
            });
            handleShowModal();
        }
    }

    /**
     * Sends reset password
     * request
     */
    const resetPassword = (e) => {
        e.preventDefault();

        //Password check
        if (meetsRequirements) {
            //Reset password
            let data = {
                password: password,
                confirmPassword: confirmPassword,
                code: code
            }
            fetch(`${window.apiSite}/forgotPassword2`, {
              method: `POST`,
              credentials: `include`,
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data)
            })
            .then(res => res.json())
            .then(json => onResetPassword(json))
        } else {
            setModal({
                title: "Error",
                body: "Your password does not meet the requirements!",
                submit: false,
                close: false,
            });
            handleShowModal();
        }
    }

    /**
     * Handles response to
     * resetting password
     * @param {JSON} data response data
     */
    const onResetPassword = (data) => {
      if (!data.error) {
        //Display success modal
        setModal({
            title: "Success",
            body: "Your password has been successfully reset. You are now being logged in...",
            submit: false,
            close: false
        });
        handleShowModal();

        //Redirect user to app
        setTimeout(() => {
            window.location.href = `${getCookie('url')}/channels`;
        }, 2500);
      } else {
        //Display error modal
        setModal({
            title: "Error",
            body: data.message,
            submit: false,
            close: false
        });
        handleShowModal();
      }
    }

    /**
     * Send reset email for
     * resetting password
     */
    const sendResetEmail = (e) => {
        e.preventDefault();

        //Send email
        let data = {
            email: email
        }
        fetch(`${window.apiSite}/forgotPassword1`, {
              method: `POST`,
              credentials: `include`,
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            })
            .then(res => res.json())
            .then(json => onSendResetEmail(json))
    }

    /**
     * Handles response to emailing
     * reset information
     * @param {JSON} data response data
     */
    const onSendResetEmail = (data) => {
        //Display success modal
        if (!data.error) {
          setModal({
              title: "Success",
              body: "An email has been sent to you with your password reset link.",
              submit: false,
              close: false
          });
          handleShowModal();
        } else {
          //Display error modal
          setModal({
              title: "Error",
              body: data.message,
              submit: false,
              close: false
          });
          handleShowModal();
        }
    }

    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
    }, []);
    
    return(
        <>
        <section className="m-8">

        {/* Modal Window */}
        <Modal show={showModal} onHide={handleShowModal} size="sm" dialogClassName="alert-modal">
            <Modal.Header closeButton>
            <Modal.Title>{modal.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {modal.body}
            </Modal.Body>
            {modal.submit || modal.close ?
                <Modal.Footer>
                { modal.submit ? 
                    <Button variant="primary" onClick={modal.submitEvent ? modal.submitEvent : handleShowModal}>{modal.submitText}</Button>
                :
                    null
                }
                { modal.close ? 
                <Button variant="danger" onClick={handleShowModal}>{modal.closeText}</Button>
                :
                null
                }
                </Modal.Footer>
            :
                null
            }
        </Modal>

        {/* If there is a OTP password */}
        {otp ? 
            <Container className="container">
            <Row className="justify-content-center">
                <Col lg={5}>
                  <h2 className="text-center mb-3">Reset Password</h2>
                  <Form>
                    <Form.Label>New Password</Form.Label>
                      <InputGroup className="mb-3">
                          <Form.Control
                              type={showPassword ? "text" : "password"} 
                              value={password}
                              placeholder="New Password" 
                              onChange={(e) => setPassword(e.target.value)}
                              required
                          />
                          <InputGroup.Prepend>
                            <Button variant="outline-secondary" onClick={() => setShowPassword(!showPassword)} tabIndex="-1">
                            <FontAwesomeIcon 
                                size="lg"
                                icon={showPassword ? faEye : faEyeSlash}  
                            />
                            </Button>
                          </InputGroup.Prepend>
                      </InputGroup>
                    <Form.Label>Confirm Password</Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                            type={showPassword ? "text" : "password"} 
                            value={confirmPassword}
                            placeholder="Confirm Password" 
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                        <InputGroup.Prepend>
                          <Button variant="outline-secondary" onClick={() => setShowPassword(!showPassword)} tabIndex="-1">
                          <FontAwesomeIcon 
                              size="lg"
                              icon={showPassword ? faEye : faEyeSlash}  
                          />
                          </Button>
                        </InputGroup.Prepend>
                      </InputGroup>
                    <Row className="align-items-center justify-content-center text-center">
                        <Col>
                        <Button 
                            type="submit" 
                            variant="primary"
                            onClick={(e) => resetOTP(e)}
                            className="btn-block"
                        >Reset Password</Button>
                        </Col>
                    </Row>
                    </Form>
                    <PasswordChecklist
                      rules={["minLength","specialChar","number","capital","match"]}
                      minLength={8}
                      value={password}
                      valueAgain={confirmPassword}
                      onChange={(isValid) => {setMeetsRequirements(isValid)}}
                    />
                </Col>
            </Row>
            </Container>
            :
            null
        }

        {/* If there is a reset code */}
        { code ? 
            <Container>
            <Row className="justify-content-center">
                <Col lg={5}>
                  <h2 className="text-center mb-3">Reset Password</h2>
                  <Form>
                    <Form.Label>New Password</Form.Label>
                      <InputGroup className="mb-3">
                          <Form.Control
                              type={showPassword ? "text" : "password"} 
                              value={password}
                              placeholder="New Password" 
                              onChange={(e) => setPassword(e.target.value)}
                              required
                          />
                          <InputGroup.Prepend>
                            <Button variant="outline-secondary" onClick={() => setShowPassword(!showPassword)} tabIndex="-1">
                            <FontAwesomeIcon 
                                size="lg"
                                icon={showPassword ? faEye : faEyeSlash}  
                            />
                            </Button>
                          </InputGroup.Prepend>
                      </InputGroup>
                    <Form.Label>Confirm Password</Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                            type={showPassword ? "text" : "password"} 
                            value={confirmPassword}
                            placeholder="Confirm Password" 
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                        <InputGroup.Prepend>
                          <Button variant="outline-secondary" onClick={() => setShowPassword(!showPassword)} tabIndex="-1">
                          <FontAwesomeIcon 
                              size="lg"
                              icon={showPassword ? faEye : faEyeSlash}  
                          />
                          </Button>
                        </InputGroup.Prepend>
                      </InputGroup>
                    <Row className="align-items-center justify-content-center text-center">
                        <Col>
                        <Button 
                            type="submit" 
                            variant="primary"
                            onClick={(e) => resetPassword(e)}
                            className="btn-block"
                        >Reset Password</Button>
                        </Col>
                    </Row>
                    </Form>
                    <PasswordChecklist
                      rules={["minLength","specialChar","number","capital","match"]}
                      minLength={8}
                      value={password}
                      valueAgain={confirmPassword}
                      onChange={(isValid) => {setMeetsRequirements(isValid)}}
                    />
                </Col>
            </Row>
            </Container>
            :
            null
        }

        {/* No password info */}
        { !id && !otp && !code ?
            <Container>
            <Row className="justify-content-center">
                <Col lg={5}>
                  <h2 className="text-center mb-3">Forgot Password</h2>
                  <Form>
                    <Form.Label>Email</Form.Label>
                      <InputGroup className="mb-3">
                          <Form.Control
                              type="email" 
                              value={email}
                              placeholder="Email" 
                              onChange={(e) => setEmail(e.target.value)}
                              required
                          />
                      </InputGroup>
                      <Row className="align-items-center justify-content-center text-center">
                          <Col>
                          <Button 
                              type="submit" 
                              variant="primary"
                              onClick={(e) => sendResetEmail(e)}
                              className="btn-block"
                          >Send Reset Email</Button>
                          </Col>
                      </Row>
                  </Form>
                </Col>
            </Row>
            </Container>
            :
            null
        }

        </section>
        </>
    );
}

//Exports
export default ResetForm;
