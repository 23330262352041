//Imports
import React, { 
  useState, 
  useEffect,
} from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import awsConnect from '../../Assets/images/integrations/amazon_connect.png';
import salesforce from '../../Assets/images/integrations/salesforce.png';
import ringCentral from '../../Assets/images/integrations/ring_central.png';
import niceInContact from '../../Assets/images/integrations/nice_incontact.jpg';
import fiveNine from '../../Assets/images/integrations/five_9.png';
import andMore from '../../Assets/images/integrations/and_more.png';

/**
 * Daily Stream integrations
 * component for home page
 */
const Integrations = () => {
    //States

    //Helpers

    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
    }, []);

    return(
        <OwlCarousel 
            className="mt-5 no-pb" 
            style={{"margin": "0 auto", "width": "50%"}} 
            dots={false}
            dotData={false} 
            items={3} 
            margin={30} 
            autoplay={true} 
            loop={true}
        >
            <div className="item">
                <div className="clients-logo">
                <img className="img-fluid" src={awsConnect} alt="Amazon Connect"/>
                </div>
            </div>
            <div className="item">
                <div className="clients-logo">
                <img className="img-fluid" src={salesforce} alt="Salesforce"/>
                </div>
            </div>
            <div className="item">
                <div className="clients-logo">
                <img className="img-fluid" src={ringCentral} alt="Ring Central"/>
                </div>
            </div>
            <div className="item">
                <div className="clients-logo">
                <img className="img-fluid" src={niceInContact} alt="Nice inContact"/>
                </div>
            </div>
            <div className="item">
                <div className="clients-logo">
                <img className="img-fluid" src={fiveNine} alt="Five9"/>
                </div>
            </div>
            <div className="item">
                <div className="clients-logo">
                <img className="img-fluid" src={andMore} alt="And More..."/>
                </div>
            </div>
        </OwlCarousel>
    );
}

//Exports 
export default Integrations;
