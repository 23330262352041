//Imports
import React, {
    useEffect,
    useState
} from 'react';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';

/**
 * Small loader 
 * for Daily Stream
 * images
 */
const SmallLoader = () => {
    //States

    //Helpers
    
    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
    }, []);

    return(
        <>
            <Container className="align-items-center text-center" style={{width: "50%", height: "50%", margin: "auto"}}>
                <Spinner variant="primary" animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </Container>
        </>
    );
}

//Exports
export default SmallLoader;
