//Imports
import React, { 
  useState, 
  useEffect,
} from 'react';
import family from '../../Assets/images/about/happy_family.png';

/**
 * Daily Stream mission
 * section for mission
 * page
 */
const MissionSection = () => {
    //States

    //Helpers

    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
    }, []);

    return(
        <section id="mission-section" className="m-8">
        <div className="container">
            <div className="row d-flex justify-content-center">
                <div className="d-flex col-lg-12 justify-content-center">
                    <h1><span className="primary-text">Our</span>
                    <span className="text-primary fw-bold"> Mission</span></h1>
                </div>
                <div className="d-flex col-12 col-lg-6 mb-6 mb-lg-0 order-lg-1 justify-content-center" style={{"maxHeight": "100%", "maxWidth": "100%"}}>
                    <img src={family} className="rounded" alt="Happy Family" style={{"maxWidth": "100%", "maxHeight": "100%"}}/>
                </div>
                <div className="col-12 col-lg-6 col-xl-5">
                    <div> <span className="badge badge-primary-soft p-2">
                            <i className="la la-exclamation ic-3x rotation"></i>
                        </span>
                    <h2 className="mt-3 font-w-5">Time and money spent commuting could be spent building stronger families and relationships</h2>
                    <p className="lead">We built a streaming platform that helps contact center leaders say "YES" to both remote work and world class performance and process.</p>
                    </div>
                </div>
            </div>
        </div>
        </section>
    );
}

//Exports
export default MissionSection;
