//Imports
import { 
    useState,
    useEffect 
} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Overview from './Overview';
import InfoCollection from './InfoCollection';
import SSNProtection from './SSNProtection';
import InfoUse from './InfoUse';
import InfoDisclosure from './InfoDisclosure';
import InfoRetention from './InfoRetention';
import Security from './Security';
import Linking from './Linking';
import Under13 from './Under13';
import Tracking from './Tracking';
import Changes from './Changes';
import Contacting from './Contacting';

/**
 * Daily Stream policy
 * component for privacy
 * page
 */
const Policy = () => {
    //States

    //Helpers

    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
    }, []);

    return(
        <Container className="p-5">
            <Tab.Container id="privacy-policy" defaultActiveKey="overview" style={{"text-align": "center"}}>
            <Row>
                <Col sm={3}>
                <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                    <Nav.Link eventKey="overview">Privacy Policy Overview</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link eventKey="information">Information Collection</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link eventKey="ssn">Social Security</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link eventKey="use">Information Use</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link eventKey="disclosure">Disclosure of Your Information</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link eventKey="retention">Information Retention</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link eventKey="security">Security</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link eventKey="linking">Links</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link eventKey="under13">Children Under the Age of 13</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link eventKey="tracking">Do Not Track</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link eventKey="changes">Changes to Our Privacy Policy</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link eventKey="contacting">Contact Us</Nav.Link>
                    </Nav.Item>
                </Nav>
                </Col>
                <Col sm={9}>
                <Tab.Content>
                    <Tab.Pane eventKey="overview">
                        <Overview/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="information">
                        <InfoCollection/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="ssn">
                        <SSNProtection/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="use">
                        <InfoUse/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="disclosure">
                        <InfoDisclosure/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="retention">
                        <InfoRetention/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="security">
                        <Security/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="linking">
                        <Linking/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="under13">
                        <Under13/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="tracking">
                        <Tracking/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="changes">
                        <Changes/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="contacting">
                        <Contacting/>
                    </Tab.Pane>
                </Tab.Content>
                </Col>
            </Row>
            </Tab.Container>
        </Container>
    );
}

//Exports
export default Policy;
