//Imports
import { 
    useState,
    useEffect 
} from 'react';

/**
 * Daily Stream privacy
 * policy tracking section
 * component
 */
const Tracking = () => {
    //States

    //Helpers

    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
    }, []);

    return(
        <>
        <h1>Do Not Track</h1>
        <p>
            We do not respond to Web browser “do not track” signals or other 
            mechanisms. Other parties may collect personally identifiable 
            information about your activities over time and across different 
            websites when a consumer uses our Site.
        </p>
        </>
    );
}

//Exports
export default Tracking;
