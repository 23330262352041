//Imports
import React, { 
  useState, 
  useEffect,
} from 'react';
import EngagementSection from '../../Components/Transformations/EngagementSection';
import Loader from '../../Fragments/Loader';

/**
 * Daily Stream engagement
 * page component
 */
const Engagement = () => {
    //States
    const [loading, setLoading] = useState(true);

    //Helpers

    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
      setLoading(false);
    }, []);
    
    return loading ? (
        <>
          <Loader/>
        </>
      ) 
      : 
      (
        <>
        <div className="page-wrapper">
          <div className="page-content">
            <EngagementSection/>
          </div>
        </div>
        </>
    );
}

//Exports
export default Engagement;
