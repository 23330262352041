//Imports
import { 
    useState,
    useEffect 
} from 'react';

/**
 * Daily Stream privacy
 * policy children under
 * 13 section component
 */
const Under13 = () => {
    //States

    //Helpers

    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
    }, []);

    return(
        <>
        <h1>Children Under the Age of 13</h1>
        <p>
            Our Platform is not intended for children under 13 years of age. 
            No one under age 13 may provide any information to or on Our Platform. 
            We do not knowingly collect personal information from children under 13. 
            If you are under 13, do not use or provide any information to or on the Site 
            or through any of its features. If We learn We have collected or received personal 
            information from a child under 13 without verification of parental consent, 
            We will delete that information. If you believe We might have any 
            information from or about a child under 13, please contact us in one of the 
            ways provided in the Contact Information section, below.
        </p>
        </>
    );
}

//Exports
export default Under13;
