//Imports
import { 
    useState,
    useEffect 
} from 'react';

/**
 * Daily Stream privacy
 * policy info use
 * section component
 */
const InfoUse = () => {
    //States

    //Helpers

    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
    }, []);

    return(
        <>
        <h1>Information Use</h1>
        <p>
            We use information that We collect about you or that 
            you provide to us for the following purposes:
        </p>
        <ul>
            <li><p>to present our Platform and its contents to you and to improve our Platform (We continually strive to improve our offerings based on the information and feedback We receive from you);</p></li>
            <li><p>to provide you with information or services that you request from us;</p></li>
            <li><p>to fulfill Our obligations to you as a customer;</p></li>
            <li><p>to provide you with notices about your account and to improve customer service (your information helps us to more effectively respond to your customer service requests and support needs);</p></li>
            <li><p>to carry out Our obligations and enforce our rights arising from any contracts entered into between you and Us, including for billing and collection;</p></li>
            <li><p>to notify you about changes to our Platform or any products or services We offer or provide through it;</p></li>
            <li><p>to allow you to participate in interactive features on our Platform and to personalize your experience (your information helps us to better respond to your individual needs);</p></li>
            <li><p>to enable communications to you regarding our services such as email and text;</p></li>
            <li><p>to work with marketing partners to provide you with products or services that may be of interest to you;</p></li>
            <li><p>to fulfill any other purpose for which you provide it and in any other way We may describe when you provide the information; and</p></li>
            <li><p>for any other purpose with your consent.</p></li>
        </ul>
        </>
    );
}

//Exports
export default InfoUse;
