//Imports
import { 
    useState,
    useEffect 
} from 'react';

/**
 * Daily Stream privacy
 * policy overview section
 * component
 */
const Overview = () => {
    //States

    //Helpers

    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
    }, []);

    return(
        <>
        <h1>Privacy Policy</h1>
        <p>
            Smart Technologies, Inc., and its affiliates and subsidiaries, 
            (collectively, <span className="fw-bold">“Smart Technologies"</span>, 
            <span className="fw-bold">“Company”</span>, <span className="fw-bold">“Us”</span>
            , <span className="fw-bold">“Our”</span>, or 
            <span className="fw-bold">“We”</span>) 
            recognize that you may be concerned about our collection, use, 
            and disclosure of your personal information. We respect your 
            privacy and are committed to providing a transparent notice of our 
            privacy practices.
        </p>
        <p>
           This policy describes the types of information We may collect from you 
           when you visit our website or use our software services and applications 
           (collectively our <span className="fw-bold">“Platform”</span> or <span className="fw-bold">“site”</span>). 
           This policy also covers information
            We collect about you offline, from third parties, or when you visit one 
            of our locations or contact us by phone. This policy describes our practices 
            for collecting, using, maintaining, protecting, and disclosing that 
            information.
        </p>
        <p>
            Please read this policy carefully to understand our policies and practices 
            regarding your information and how We will treat it. This policy may 
            change from time to time (see Changes to Our Privacy Policy). 
        </p>
        </>
    );
}

//Exports
export default Overview;
