//Imports
import React, { 
  useState, 
  useEffect,
} from 'react';
import { Link } from 'react-router-dom';
import E404 from '../../Assets/images/404.png';

/**
 * Daily Stream 404
 * error section component
 * for 404 error page
 */
const Section404 = () => {
    //States
    
    //Helpers

    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
    }, []);
    
    return(
        <section className="fullscreen-banner p-0 m-8">
            <div className="container h-100">
            <div className="row h-100">
                <div className="col-12 text-center h-100 d-flex align-items-center">
                <div className="w-100"><img className="img-fluid d-inline mb-5" src={E404} alt="404 Error"/>
                <h2>Oops! Page Not Found</h2>
                <div className="col-lg-6 col-md-10 mx-auto">
                <h6>You’re either misspelling the URL
                or requesting a page that's no longer here.</h6>
                <Link to="/"><a className="btn btn-primary">Back To Home Page</a></Link>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>
    );
}

//Exports
export default Section404;
