//Imports
import React, { 
  useState, 
  useEffect,
} from 'react';
import Loader from '../Fragments/Loader';
import AboutSection from '../Components/About/AboutSection';

/**
 * Daily Stream about
 * us page component
 */
const About = () => {
    //States
    const [isLoading, setIsLoading] = useState(true);

    //Helpers

    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
      setIsLoading(false);
    }, []);
    
    return isLoading ? 
    (
      <Loader/>
    )
    :
    (
      <>
        <div className="page-wrapper">
          <div className="page-content">
            <AboutSection/>
          </div>
        </div>
      </>
    );
}

//Exports
export default About;
