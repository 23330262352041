//Imports
import { 
    useState,
    useEffect 
} from 'react';

/**
 * Daily Stream privacy
 * policy information retention 
 * section component
 */
const InfoRetention = () => {
    //States

    //Helpers

    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
    }, []);

    return(
        <>
        <h1>How Long We Retain Your Information</h1>
        <p>
            To the extent permissible by applicable law, We will retain your personal information for such period as necessary to satisfy or to fulfill the following:
        </p>
        <ul>
            <li><p>the purposes for which that personal information was provided;</p></li>
            <li><p>an identifiable and ongoing business need, including record-keeping;</p></li>
            <li><p>a specific legal or regulatory requirement; and/or</p></li>
            <li><p>a requirement to retain records that may be relevant to any notified regulatory investigations or active legal proceedings</p></li>
        </ul>
        <p>
            <span className="fw-bold">For Illinois residents</span>, Our Site may collect biometric information, 
            as defined under Illinois law, in furtherance of the services 
            requested by you, someone on your behalf, or your employer. We 
            will not retain any biometric information beyond the intended purpose 
            of collecting such biometric information or beyond three (3) years of your 
            last interaction with Us, whichever occurs first.
        </p>
        </>
    );
}

//Exports
export default InfoRetention;
