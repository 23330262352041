//Imports
import React, {
    useEffect,
    useState
} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

/**
 * Daily Stream admin setup
 * form for sign up
 * page
 */
const AdminSetupForm = () => {
    //States
    const [companyName, setCompanyName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [monitors, setMonitors] = useState(2);
    const [modal, setModal] = useState({
        title: "", 
        body: "", 
        submit: false, 
        submitText: "", 
        submitEvent: null, 
        close: false, 
        closeText: ""
    });
    const [showModal, setShowModal] = useState(false);

    //Helpers
    /**
     * Handles opening and
     * closing the modal 
     */
    const handleShowModal = () => setShowModal(!showModal);

    /**
     * Sends sign up
     * data
     */
    const adminSetup = (e) => {
        e.preventDefault();

        //Validate user sign up data
        let valid = validateAdminSetup();

        //Check all values are filled out
        if (!valid) {
          //Display error message
          setModal({
            title: "Error",
            body: "Please verify all fields have been filled out correctly. The number of monitors must be between 1 and 2. The phone must be 10 digits long.",
            submit: false,
            confirm: false
          });
          handleShowModal();
        } else {
          //Send sign up data
          let setupData = {
            companyName: companyName,
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
            monitors: monitors
          }
          
          fetch(`${window.apiSite}/register`, {
            method: `POST`,
            credentials: `include`,
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(setupData)
          })
          .then(res => res.json())
          .then(json => onAdminSetup(json))
        }
    }

    /**
     * Executes on response
     * to user sign up
     * @param {JSON} data 
     *  @param {Number} data.success status of registration request
     *  @param {String} data.message error message with registration request
     */
    const onAdminSetup = (data) => {
      //Check sign up state
      if (data.success) {
        //Show success 
        setModal({
          title: "Success",
          body: "You have successfully registered the company. The email provided should receive further instructions for account setup.",
          submit: false,
          close: false
        });
        handleShowModal();
      } else {
        //Show error
        setModal({
          title: "Error",
          body: data.message,
          submit: false,
          close: false
        });
        handleShowModal();
      }
    }

    /**
     * Validates user sign up data
     * @param {Object} data sign up data 
     * @return {Object} validation status
     */
    const validateAdminSetup = () => {
      //Company name must not be null and must not be empty
      if (!companyName || companyName.length <= 0) {
        return false;
      }

      //First name must not be null and must not be empty
      if (!firstName || firstName.length <= 0) {
        return false;
      }

      //Last name must not be null and must not be empty
      if (!lastName || lastName.length <= 0) {
        return false;
      }

      //Monitors must not be null and must be between 1 and 2
      if (!monitors || (monitors > 2 || monitors < 1)) {
        return false;
      }

      //All data is valid
      return true;
    }

    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
    }, []);

    return(
      <>
        <section className="m-8">
          {/* Modal Window */}
          <Modal show={showModal} onHide={handleShowModal} size="sm" dialogClassName="alert-modal">
            <Modal.Header closeButton>
            <Modal.Title>{modal.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {modal.body}
            </Modal.Body>
            {modal.submit || modal.close ?
                <Modal.Footer>
                { modal.submit ? 
                    <Button variant="primary" onClick={modal.submitEvent ? modal.submitEvent : handleShowModal}>{modal.submitText}</Button>
                :
                    null
                }
                { modal.close ? 
                <Button variant="danger" onClick={handleShowModal}>{modal.closeText}</Button>
                :
                null
                }
                </Modal.Footer>
            :
                null
            }
          </Modal>

          <Container>
            <Row>
              <Col>
              <form id="registration_form" onSubmit={adminSetup}>
                <div className="form-group mb-2">
                  <label>Company Name</label>
                  <input 
                    id="form_cname" 
                    type="text" 
                    name="company name" 
                    className="form-control" 
                    placeholder="Company Name" 
                    required="required" 
                    data-error="Company Name is required."
                    onChange={(e) => setCompanyName(e.target.value)}
                    />
                  <div className="help-block with-errors"></div>
                </div>
                <div className="form-group mb-2">
                  <label>First Name</label>
                  <input 
                    id="form_fname" 
                    type="text" 
                    name="first name" 
                    className="form-control" 
                    placeholder="First Name" 
                    required="required" 
                    data-error="First Name is required."
                    onChange={(e) => setFirstName(e.target.value)}
                    />
                  <div className="help-block with-errors"></div>
                </div>
                <div className="form-group mb-2">
                  <label>Last Name</label>
                  <input 
                    id="form_lname" 
                    type="text" 
                    name="last name" 
                    className="form-control" 
                    placeholder="Last Name" 
                    required="required" 
                    data-error="Last Name is required."
                    onChange={(e) => setLastName(e.target.value)}
                    />
                  <div className="help-block with-errors"></div>
                </div>
                <div className="form-group mb-2">
                  <label>Email</label>
                  <input 
                    id="form_email" 
                    type="email" 
                    name="email" 
                    className="form-control" 
                    placeholder="Email Address" 
                    required="required" 
                    data-error="Email is required."
                    onChange={(e) => setEmail(e.target.value)}
                    />
                  <div className="help-block with-errors"></div>
                </div>
                <div className="form-group mb-2">
                  <label>Phone</label>
                  <input 
                    id="form_phone" 
                    type="text" 
                    name="phone" 
                    className="form-control" 
                    placeholder="Phone Number" 
                    data-error="Phone Number is required."
                    onChange={(e) => setPhone(e.target.value)}
                    />
                  <div className="help-block with-errors"></div>
                </div>
                <div className="form-group mb-2">
                  <label>Number of Monitors</label>
                  <input 
                    id="monitors" 
                    type="number" 
                    min="1"
                    max="2"
                    name="monitors" 
                    className="form-control" 
                    placeholder="2" 
                    required="required"
                    data-error="Number of monitors is required"
                    onChange={(e) => setMonitors(e.target.value)}
                    />
                  <div className="help-block with-errors"></div>
                </div>
                <div className="form-group mt-2 mb-2">
                </div><button type="submit" className="btn btn-primary btn-block">Sign Up</button>
              </form>
              </Col>
            </Row>
          </Container>
          </section>
        </>
    );
}

//Exports
export default AdminSetupForm;
