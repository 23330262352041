import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const styles = {
  colors: {
    primary: {
      color: "#007bff"
    }
  },
  hidden: {
    display: 'none'
  },
  visible: {
    display: 'table-cell'
  },
  font: {
    fontFamily: 'Roboto, Helvetica, sans-serif'
  },
  table: {
    maxHeight: '400px !important'
  },
  card: {
    header: {
      backgroundColor: '#727cf5',
      color: '#fff'
    }
  },
  icons: {
    connected: {
      color: '#10b759',
    },
    disconnected: {
      color: '#ff3366'
    },
    save: {
      color: '#10b759',
      fontSize: '1.2rem',
      cursor: 'pointer',
      marginRight: '10px'
    },
    cancel: {
      color: '#fbbc06',
      fontSize: '1.2rem',
      cursor: 'pointer'
    },
    edit: {
      color: '#007bff',
      fontSize: '1.2rem',
      cursor: 'pointer'
    },
    danger: {
      color: '#ff3366',
      fontSize: '1.2rem',
      cursor: 'pointer'
    }
  },
  alignMiddle: {
    alignItems: "center"
  },
  spinner: {
    height: "5rem",
    width: "5rem"
  }
}

const SsoErrorForm = () => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const handleShowErrorMessage = () => setShowErrorMessage(!showErrorMessage);
    return <>
      <section id="error-section" className="m-8">
        <Container>
          <Row>
            <Col>
              <Alert variant="danger" onClose={handleShowErrorMessage} dismissible>
                <Alert.Heading>That is an invalid email address!</Alert.Heading>
                <hr/>
                <p>
                  Please ensure you're using an email address that is approved by your admin.
                  If you have further difficulties, please contact us.
                </p>
              </Alert>
            </Col>
          </Row>
        </Container>
      </section>
     </>
}
//Exports
export default SsoErrorForm;
