//Imports
import { 
    useState,
    useEffect 
} from 'react';

/**
 * Daily Stream privacy
 * policy information disclosure 
 * section component
 */
const InfoDisclosure = () => {
    //States

    //Helpers

    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
    }, []);

    return(
        <>
        <h1>Disclosure of Your Information</h1>
        <p>
            We may disclose information that We collect about you or that you provide Us directly as described in this privacy policy:
        </p>
        <ul>
            <li><p>to our subsidiaries and affiliates;</p></li>
            <li><p>to contractors, service providers, and other third parties We use to support our business;</p></li>
            <li><p>to a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Company's assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by Company about our Website users is among the assets transferred;</p></li>
            <li><p>to fulfill the purpose for which you provide the information;</p></li>
            <li><p>for any other purpose disclosed by us when you provide the information; </p></li>
            <li><p>with your consent.</p></li>
        </ul>
        <p>
            We may also disclose your personal information:
        </p>
        <ul>
            <li><p>to comply with any court order, law, or legal process, including to respond to any government, regulatory, or law enforcement request;</p></li>
            <li><p>to enforce or apply our Terms of Use and other agreements, including for billing and collection purposes; and</p></li>
            <li><p>if We believe disclosure is necessary or appropriate to protect the rights, property, or safety of Company, our customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</p></li>
        </ul>
        <p>
            We may disclose aggregated information about our users and deidentified data or information that does not identify an individual.
        </p>
        </>
    );
}

//Exports
export default InfoDisclosure;
