//Imports
import React, { 
  useState, 
  useEffect,
} from 'react';
import ProcessSection from '../../Components/Transformations/ProcessSection';
import Loader from '../../Fragments/Loader';

/**
 * Daily Stream process
 * improvement page component
 */
const Process = () => {
    //States
    const [loading, setLoading] = useState(true);

    //Helpers

    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
      setLoading(false);
    }, []);
    
    return loading ? (
      <>
        <Loader/>
      </>
    ) 
    : 
    (
        <>
        <div className="page-wrapper">
          <div className="page-content">
            <ProcessSection/>
          </div>
        </div>
        </>
    );
}

//Exports
export default Process;
