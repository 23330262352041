//Imports
import React, { 
  useState, 
  useEffect,
} from 'react';
import Loader from '../Fragments/Loader';
import SsoSetupForm from '../Components/SsoSetup/SsoSetupForm';

/**
 * Daily Stream user
 * setup page component
 */
const SsoSetup = () => {
    //States
    const [isLoading, setIsLoading] = useState(true);

    //Helpers

    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
      setIsLoading(false);
    }, []);
    
    return isLoading ? 
    (
      <Loader/>
    )
    :
    (
      <>
        <div className="page-wrapper">
          <div className="page-content">
            <SsoSetupForm/>
          </div>
        </div>
      </>
    );
}

//Exports
export default SsoSetup;
