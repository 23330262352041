//Imports
import React, { 
  useState, 
  useEffect,
} from 'react';
import CoachingSection from '../../Components/Transformations/CoachingSection';
import Loader from '../../Fragments/Loader';

/**
 * Daily Stream coaching
 * page component
 */
const Coaching = () => {
    //States
    const [loading, setLoading] = useState(true);

    //Helpers

    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
      setLoading(false);
    }, []);
    
    return loading ? (
      <>
        <Loader/>
      </>
    ) 
    : 
    (
        <>
          <div className="page-wrapper">
            <div className="page-content">
              <CoachingSection/>
            </div>
          </div>
        </>
    );
}

//Exports
export default Coaching;
