/**
 * Sets cookie
 * @param {String} name the cookie name
 * @param {*} value the cookie value
 * @param {Number} exdays the number of days until cookie expires
 */
const setCookie = (name, value, exdays) => {
    //Set variables
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expiry = `expires=${d.toUTCString()}`;

    //Set cookie
    document.cookie = `${name}=${value};${expiry};path=/;domain=dailystream.com`;
}

/**
 * Gets cookie
 * @param {String} name the name of the cookie to retrieve
 * @returns {*} the value of the cookie
 */
const getCookie = (name) => {
    //Set variables 
    let cookie = `${name}=`;
    let decodedCookie = decodeURIComponent(document.cookie);
    let splitCookie = decodedCookie.split(';');
    
    //Get cookie
    for (let i = 0; i < splitCookie.length; i++) {
        let c = splitCookie[i];

        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }

        if (c.indexOf(cookie) == 0) {
            return c.substring(cookie.length, c.length);
        }
    }
    return "";
}

//Exports
export {
    setCookie,
    getCookie
}