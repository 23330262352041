//Imports
import React, { 
  useState, 
  useEffect,
} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import movie from '../../Assets/video/dailystream_movie.mp4';
import '../../Assets/css/modal.css'

/**
 * Daily Stream video
 * section component
 * for home page
 */
const VideoSection = () => {
    //States
    const [showModal, setShowModal] = useState(false);
    const [modal, setModal] = useState({
      title: "", 
      body: "", 
      submit: false, 
      submitText: "", 
      submitEvent: null, 
      close: false, 
      closeText: ""
    });

    //Helpers
    /**
     * Handles opening and closing modal
     */
    const handleShowModal = () => setShowModal(!showModal);

    /**
     * Opens calendly info
     * in modal window
     */
    const openCalendly = () => {
      setModal({
        title: "Schedule a Demo!",
        body: <iframe src='https://calendly.com/ryandailystream/consultation' style={{"width": "100%", "height": "100%"}}></iframe>,
        submit: false,
        close: false
      });
      handleShowModal();
    }

    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
    }, []);
    
    return(
        <>
            {/* Modal Window */}
            <Modal show={showModal} onHide={handleShowModal} dialogClassName="calendly-modal" contentClassName="expanded">
                <Modal.Header closeButton>
                <Modal.Title>{modal.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.body}</Modal.Body>
                <Modal.Footer>
                { modal.submit ? 
                    <Button variant="primary" onClick={modal.submitEvent ? modal.submitEvent : handleShowModal}>{modal.submitText}</Button>
                :
                    null
                }
                    { modal.close ? 
                    <Button variant="danger" onClick={handleShowModal}>{modal.closeText}</Button>
                :
                    null
                }
                </Modal.Footer>
            </Modal>

            {/* Video Section */}
            <section id="video-section" className="m-8">
            <div className="container">
                <div className="row align-items-center justify-content-between">
                <div className="col-12 col-lg-6 mb-6 mb-lg-0">
                    <video type="video/mp4" controls>
                    <source src={movie} type="video/mp4"/>
                    </video>
                </div>
                <div className="col-12 col-lg-6 col-xl-5">
                    <div> <span className="badge badge-primary-soft p-2">
                            <i className="la la-exclamation ic-3x rotation"></i>
                        </span>
                    <h2 className="mt-3">Visibility Impacts Everything</h2>
                    <p className="lead">Daily Stream helps contact centers improve performance and process with 100% visibility
                        into day-to-day operations using breakthrough continuous screen and audio streaming technology.
                    </p>
                    </div>
                    <div className="d-flex flex-wrap justify-content-start">
                    <div className="mb-3 me-4">
                        <div className="d-flex align-items-center">
                        <div className="badge-primary-soft rounded p-1">
                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                            <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                        </div>
                        <p className="mb-0 ms-3">Compliance and HR</p>
                        </div>
                    </div>
                    <div className="mb-3 me-4">
                        <div className="d-flex align-items-center">
                        <div className="badge-primary-soft rounded p-1">
                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                            <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                        </div>
                        <p className="mb-0 ms-3">Next Gen QM</p>
                        </div>
                    </div>
                    <div className="mb-3 me-4">
                        <div className="d-flex align-items-center">
                        <div className="badge-primary-soft rounded p-1">
                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                            <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                        </div>
                        <p className="mb-0 ms-3">Real World Training Videos</p>
                        </div>
                    </div>
                    <div className="mb-3 me-4">
                        <div className="d-flex align-items-center">
                        <div className="badge-primary-soft rounded p-1">
                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                            <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                        </div>
                        <p className="mb-0 ms-3">Process Engineering</p>
                        </div>
                    </div>
                    </div> <a className="btn btn-outline-primary mt-5" onClick={openCalendly}>
                            SCHEDULE DEMO
                        </a>
                </div>
                </div>
            </div>
            </section>
        </>
    );
}

//Exports
export default VideoSection;
