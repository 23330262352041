//Imports
import React, { 
  useState, 
  useEffect
} from 'react';
import bg02 from '../../Assets/images/bg/02.png';
import globe from '../../Assets/images/icon/globe.png';
import monitor from '../../Assets/images/icon/monitor.png';
import camera from '../../Assets/images/icon/camera.png';
import cloud from '../../Assets/images/icon/cloud.png';
import integration from '../../Assets/images/icon/integration.png';
import banknotes from '../../Assets/images/icon/banknotes.png';

/**
 * Daily Stream features
 * section component
 * for home page 
 */
const FeaturesSection = () => {
    //States

    //Helpers
    
    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
    }, []);

    return(
        <>
            {/* Features Section */}
            <section id="features-section" className="custom-pt-1 custom-pb-2 bg-dark position-relative" data-bg-img={bg02}>
            <div className="container">
                <div className="row align-items-end">
                <div className="col-lg-4 col-md-6">
                    <div className="bg-primary-soft rounded p-5">
                    <div className="d-flex align-items-center mb-4">
                        <div className="me-3">
                        <img className="img-fluid feature-icons" src={globe} alt="Globe"/>
                        </div>
                        <h5 className="m-0 text-light">Multiple Monitors</h5>
                    </div>
                    <p className="mb-0">Monitor up to 2 screens per user.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="bg-primary-soft rounded p-5">
                    <div className="d-flex align-items-center mb-4">
                        <div className="me-3">
                        <img className="img-fluid feature-icons" src={monitor} alt="Monitor"/>
                        </div>
                        <h5 className="m-0 text-light">Real Time or Recorded</h5>
                    </div>
                    <p className="mb-0">Screens and Audio are continuously broadcasting and recording so you can watch any time any where.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 mt-6 mt-md-0">
                    <div className="bg-primary-soft rounded p-5">
                    <div className="d-flex align-items-center mb-4">
                        <div className="me-3">
                        <img className="img-fluid feature-icons" src={camera} alt="Camera"/> 
                        </div>
                        <h5 className="m-0 text-light">Archive and Save Clips</h5>
                    </div>
                    <p className="mb-0">Every user can quickly create video clips for coaching and communication. Fast. Effective. Efficient.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 mt-6">
                    <div className="bg-primary-soft rounded p-5">
                    <div className="d-flex align-items-center mb-4">
                        <div className="me-3">
                        <img className="img-fluid feature-icons" src={cloud} alt="Cloud"/>
                        </div>
                        <h5 className="m-0 text-light">Cloud Based, No Downloads</h5>
                    </div>
                    <p className="mb-0">Onboard your teams in minutes.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 mt-6">
                    <div className="bg-primary-soft rounded p-5">
                    <div className="d-flex align-items-center mb-4">
                        <div className="me-3">
                        <img className="img-fluid feature-icons" src={integration} alt="Integrations"/>
                        </div>
                        <h5 className="m-0 text-light">Integrations Available</h5>
                    </div>
                    <p className="mb-0">Daily Stream integrations enable you to add the qualitative power of visibility to your UCaaS, CRM, and CCaaS quantitative metrics.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 mt-6">
                    <div className="bg-primary-soft rounded p-5">
                    <div className="d-flex align-items-center mb-4">
                        <div className="me-3">
                        <img className="img-fluid feature-icons" src={banknotes} alt="Bank Notes"/>
                        </div>
                        <h5 className="m-0 text-light">Affordable, lightweight and HD</h5>
                    </div>
                    <p className="mb-0">Daily Stream's breakthrough technology offers low cost, all-day, HD streaming. Hardware and bandwidth requirements are minimal and typical of WFH environments.</p>
                    </div>
                </div>
                </div>
            </div>
                <div className="shape-1" style={{"height": "150px", "overflow": "hidden"}}>
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{"height": "100%", "width": "100%"}}>
                <path d="M0.00,49.98 C150.00,150.00 271.49,-50.00 500.00,49.98 L500.00,0.00 L0.00,0.00 Z" style={{"stroke": "none", "fill": "#fff"}}></path>
                </svg>
            </div>
            <div className="shape-1 bottom" style={{"height": "200px", "overflow": "hidden"}}>
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{"height": "100%", "width": "100%"}}>
                <path d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z" style={{"stroke": "none", "fill": "#fff"}}></path>
                </svg>
            </div>
            </section>
        </>
    );
}

//Exports
export default FeaturesSection;
