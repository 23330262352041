//Imports
import React from 'react';
import { Outlet } from 'react-router-dom';
import NavBar from './Navbar';
import Footer from './Footer';

/**
 * Wraps app routes
 * with navbar and footer.
 */
const Layout = () => {
    /**
     * We can add authentication
     * here in the future if 
     * needed
     */
    return(
      <>
        <NavBar/>
        <Outlet/>
        <Footer/>
      </>
    );
}

//Exports
export default Layout;