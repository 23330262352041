//Imports
import React, {
  useState,
  useEffect
} from 'react';
import Loader from '../Fragments/Loader';
import AdminSetupForm from '../Components/AdminSetup/AdminSetupForm';

/**
 * Admin Setup page for Daily Stream
 */
const AdminSetup = () => {
  const [isLoading, setIsLoading] = useState(true);

  /**
   * Executes after component
   * has rendered
   */
  useEffect(() => {
    setIsLoading(false);
  }, []);

  return isLoading ? 
  (
    <Loader />
  )
  :
  (
    <>
      <AdminSetupForm/>
    </>
  );
}

//Exports
export default AdminSetup;
