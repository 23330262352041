// Imports
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './Fragments/Layout';
import Home from './Pages/Home';
import Login from './Pages/Login';
import Reset from './Pages/Reset';
import Setup from './Pages/Setup';
import SsoSetup from './Pages/SSoSetup';
import Twofac from './Pages/Twofac';
import About from './Pages/About';
import Mission from './Pages/Mission';
import Privacy from './Pages/Privacy';
import Coaching from './Pages/Transformations/Coaching';
import Compliance from './Pages/Transformations/Compliance';
import Engagement from './Pages/Transformations/Engagement';
import Process from './Pages/Transformations/Process';
import QM from './Pages/Transformations/QM';
import SsoError from './Pages/SsoError';
import E404 from './Pages/E404';
import AdminSetup from './Pages/AdminSetup';
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Layout/>}>
          <Route exact path="/" element={<Home/>}/>
          <Route exact path="/login" element={<Login/>}/>
          <Route exact path="/login/:id/:otp" element={<Reset/>}/>
          <Route exact path="/reset" element={<Reset/>}/>
          <Route exact path="/setup" element={<Setup/>}/>
          <Route exact path="/ssosetup" element={<SsoSetup/>}/>
          <Route exact path="/code" element={<Twofac/>}/>
          <Route exact path="/about-us" element={<About/>}/>
          <Route exact path="/mission" element={<Mission/>}/>
          <Route exact path="/privacy" element={<Privacy/>}/>
          <Route exact path="/sso-error" element={<SsoError/>}/>
          <Route exact path="/transformations/coaching" element={<Coaching/>}/>
          <Route exact path="/transformations/compliance" element={<Compliance/>}/>
          <Route exact path="/transformations/engagement" element={<Engagement/>}/>
          <Route exact path="/transformations/process-improvement" element={<Process/>}/>
          <Route exact path="/transformations/qm" element={<QM/>}/>
          <Route exact path="/admin-setup/85e5cb516a690f927b938554a7c3ddf52aeb20754d56fa8343454e2925ef8232" element={<AdminSetup/>}/>
        </Route>
        <Route path="*" element={<E404/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
