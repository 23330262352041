//Imports
import React, { useState, useEffect, } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye, faSpinner, faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { useNavigate } from 'react-router-dom';
import '../../Assets/css/modal.css';
import btnGoogle from '../../Assets/images/btn_google_normal.png'

//Icon Styles
const styles = {
  colors: {
    primary: {
      color: "#007bff"
    }
  },
  hidden: {
    display: 'none'
  },
  visible: {
    display: 'table-cell'
  },
  font: {
    fontFamily: 'Roboto, Helvetica, sans-serif'
  },
  table: {
    maxHeight: '400px !important'
  },
  card: {
    header: {
      backgroundColor: '#727cf5',
      color: '#fff'
    }
  },
  icons: {
    connected: {
      color: '#10b759',
    },
    disconnected: {
      color: '#ff3366'
    },
    save: {
      color: '#10b759',
      fontSize: '1.2rem',
      cursor: 'pointer',
      marginRight: '10px'
    },
    cancel: {
      color: '#fbbc06',
      fontSize: '1.2rem',
      cursor: 'pointer'
    },
    edit: {
      color: '#007bff',
      fontSize: '1.2rem',
      cursor: 'pointer'
    },
    danger: {
      color: '#ff3366',
      fontSize: '1.2rem',
      cursor: 'pointer'
    }
  },
  alignMiddle: {
    alignItems: "center"
  },
  spinner: {
    height: "5rem",
    width: "5rem"
  }
}

/**
 * Daily Stream login
 * form component
 * for login page
 */
const LoginForm = () => {
    //States
    const [loginData, setLoginData] = useState({
        email: "", 
        password: ""
    });
    const [modal, setModal] = useState({
        title: "", 
        body: "", 
        submit: false, 
        submitText: "", 
        submitEvent: null, 
        close: false, 
        closeText: "",
        hideEvent: null
    });
    const [showModal, setShowModal] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    let navigate = useNavigate();

    //Helpers
    /**
     * Handles opening and
     * closing the modal
     */
    const handleShowModal = () => setShowModal(!showModal);

    /**
     * Submit login data
     * to API
     */
    const sendLogin = (e) => {
        //Prevent refresh
        e.preventDefault();

        //Validate login data
        if (loginData.email.length > 0 && loginData.password.length > 0) {
            //Validate credentials modal
            setModal({
                title: "Login",
                body: <>
                        <Row className="d-flex align-items-center justify-content-center text-center mb-3">
                            <Col>
                            <FontAwesomeIcon 
                                size="2x"
                                style={styles.icons.disconnected}
                                icon={faSpinner}  
                                spin
                            />
                            </Col>
                        </Row>
                        <Row className="d-flex align-items-center justify-content-center text-center mb-auto">
                            <Col>
                                <p className="mb-auto">Validating login...</p>
                            </Col>
                        </Row>
                    </>,
                submit: false,
                close: false,
                hideEvent: false
            });
            handleShowModal();

            fetch(`${window.apiSite}/login`, {
              method: "POST",
              credentials: `include`,
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(loginData),
            })
            .then(async (res) => {
              if (!res.ok) {
                console.error(res);
                onLogin(false);
              } else {
                onLogin(true);
              }
            });
        } else {
            //Display error message 
            setModal({
                title: "Login",
                body: <>
                        <Row className="d-flex align-items-center justify-content-center text-center mb-3">
                            <Col>
                            <FontAwesomeIcon 
                                size="2x"
                                style={styles.icons.disconnected}
                                icon={faTimesCircle}  
                            />
                            </Col>
                        </Row>
                        <Row className="d-flex align-items-center justify-content-center text-center mb-auto">
                            <Col>
                                <p className="mb-auto">All fields must be filled out!</p>
                            </Col>
                        </Row>
                    </>,
                submit: false,
                close: false,
                hideEvent: false
            });
            handleShowModal();
        }
    }
    
    /**
     * Login callback for response
     * from API 
     * @param {JSON} data json data returned from api on login request 
     *  @param {Number} data.login returned login state
     *  @param {String} data.genUserId token returned on successful login
     */
    const onLogin = (data) => {
        //Check login status
        if (data) {
            //Alert successful login
            setModal({
                title: "Success",
                body: <>
                        <Row className="d-flex align-items-center justify-content-center text-center mb-3">
                            <Col>
                            <FontAwesomeIcon 
                                size="2x"
                                style={styles.icons.connected}
                                icon={faCheckCircle}  
                            />
                            </Col>
                        </Row>
                        <Row className="d-flex align-items-center justify-content-center text-center mb-auto">
                            <Col>
                                <p className="mb-auto">Redirecting to authentication...</p>
                            </Col>
                        </Row>
                    </>,
                submit: false,
                close: false,
                hideEvent: false
            });
            handleShowModal();

            setTimeout(() => {
                navigate('/code');
            }, 2000);
        } else {
            let defaultMsg = "Invalid Username or Password!";
            let msg = data.message || defaultMsg;

            setModal({
                title: 'Error',
                body: <>
                        <Row className="d-flex align-items-center justify-content-center text-center mb-3">
                            <Col>
                            <FontAwesomeIcon 
                                size="2x"
                                style={styles.icons.disconnected}
                                icon={faTimesCircle}  
                            />
                            </Col>
                        </Row>
                        <Row className="d-flex align-items-center justify-content-center text-center mb-auto">
                            <Col>
                                <p className="mb-auto">{msg}</p>
                            </Col>
                        </Row>
                    </>,
                submit: false,
                close: false,
                hideEvent: false
            });
            handleShowModal();
        }
    }

    const withGoogle = (e) => {
        e.preventDefault();
        window.location = `${window.apiSite}/google/login`
    }
    /**
     * Sets email 
     */
    const setEmail = (e) => {
        setLoginData(prevState => ({
            email: e.target.value, 
            password: prevState.password
        }));
    }

    /**
     * Sets password
     */
    const setPassword = (e) => {
        setLoginData(prevState => ({
            email: prevState.email,
            password: e.target.value
        }));
    }

    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
      
    }, []);

    return(
        <section id="login-form" className="m-8">
        
        {/* Modal Window */}
        <Modal show={showModal} onHide={modal.hideEvent ? () => modal.hideEvent: handleShowModal} size="sm" dialogClassName="alert-modal">
            <Modal.Header closeButton>
            <Modal.Title>{modal.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {modal.body}
            </Modal.Body>
            {modal.submit || modal.close ?
                <Modal.Footer>
                { modal.submit ? 
                    <Button variant="primary" onClick={modal.submitEvent ? modal.submitEvent : handleShowModal}>{modal.submitText}</Button>
                :
                    null
                }
                { modal.close ? 
                <Button variant="danger" onClick={handleShowModal}>{modal.closeText}</Button>
                :
                null
                }
                </Modal.Footer>
            :
                null
            }
        </Modal>

        <Container>
            <Row className="justify-content-center">
            <Col lg={5}>
                <Col>
                <h2 className="text-center mb-3">Sign In</h2>
                <Form autoComplete="off">
                    <Form.Label>Email</Form.Label>
                    <InputGroup className="mb-3">
                        <Form.Control
                            type="email"
                            placeholder="Email" 
                            value={loginData.email}
                            onChange={(e) => setEmail(e)}
                            required
                        />
                    </InputGroup>
                    <Form.Label>Password</Form.Label>
                    <InputGroup className="mb-3">
                        <Form.Control
                            type={showPassword ? "text" : "password"} 
                            value={loginData.password}
                            placeholder="Password" 
                            onChange={(e) => setPassword(e)}
                            required
                        />
                        <InputGroup.Prepend>
                          <Button variant="outline-secondary" onClick={() => setShowPassword(!showPassword)} tabIndex="-1">
                          <FontAwesomeIcon 
                              size="lg"
                              icon={showPassword ? faEye : faEyeSlash}  
                          />
                          </Button>
                        </InputGroup.Prepend>
                    </InputGroup>
                    <Row className="align-items-center justify-content-center text-center">
                        <Col>
                        <Button 
                            type="submit" 
                            variant="primary"
                            onClick={(e) => sendLogin(e)}
                            className="btn-block"
                        >Login</Button>
                        <p 
                          style={{textAlign: "left", cursor: "pointer"}}
                          onClick={() => navigate("/reset")}>Forgot Password?</p>
                        </Col>
                    </Row>
                </Form>
                <Form>
                    <Button 
                    onClick={(e) => withGoogle(e)}
                    variant="primary" 
                    type="submit" 
                    style={{padding: "0px", border: "0px"}}
                    className="bg-white">
                      <Image src={btnGoogle}/>
                    </Button>
                </Form>
                </Col>
            </Col>
            </Row>
        </Container>
        </section>
    );
}

//Exports
export default LoginForm;
