//Imports
import React, {
    useEffect,
    useState
} from 'react';
import SmallLoader from '../../Fragments/SmallLoader';
import engagement from '../../Assets/images/transformations/engagement.jpg';

/**
 * Daily Stream engagement
 * section for engagement
 * page
 */
const EngagementSection = () => {
    //States
    const [imageLoading, setImageLoading] = useState(true);

    //Helpers
    /**
     * Sets image loading
     * state
     */
    const imageLoaded = () => setImageLoading(!imageLoading);

    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
    }, []);

    return(
        <>
            <section id="engagement" className="m-8">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-12 d-flex justify-content-center">
                        <h1><span className="text-primary">Engagement</span>
                        </h1>
                    </div>
                    <div className="col-md-6 d-flex justify-content-start align-content-center">
                        <img 
                            src={engagement} 
                            style={{borderRadius: "5%", maxWidth: "100%", maxHeight: "100%"}}
                            onLoad={imageLoaded}
                            alt="Engagement"
                        />
                        {imageLoading && <SmallLoader/> }
                    </div>
                    <div className="col-md-6 d-flex flex-wrap">
                    <h4>Daily Stream is a unique collaborative tool. In mere seconds, agents can click and share clips within their daily stream. Plays of the day, coaching requests, and agent error catching not only improve engagement but also provide invaluable real world insights. No more ambiguity and isolation. Every agent has the tool to be a star! Specific use cases and benefits include:</h4>
                    <ul>
                        <li>Engagement is built into everyday</li>
                        <li>Reinforce a culture of accountability</li>
                        <li>Plays of the day library</li>
                        <li>Quarterly review videos</li>
                        <li>Getting noticed is in the agents control</li>
                        <li>Agents are more than just a metric</li>
                    </ul> 
                    </div>
                    </div>
                </div>
            </section>
        </>
    );
}

//Exports
export default EngagementSection;
