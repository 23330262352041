//Imports
import React, {
    useState,
    useEffect
} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import { HashLink  as Link } from 'react-router-hash-link';
import bg03 from '../Assets/images/bg/03.png';

/**
 * Footer component for 
 * Daily Stream
 * @param {Object} props component props 
 */
const Footer = (props) => {
    //States

    //Helpers

    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
    }, []);
    
    return(
        <footer className="py-11 bg-primary position-relative" data-bg-img={bg03} style={{backgroundImage: bg03}}>
        <div className="shape-1" style={{height: "150px", overflow: "hidden"}}>
            <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: "100%", width: "100%"}}>
            <path d="M0.00,49.98 C150.00,150.00 271.49,-50.00 500.00,49.98 L500.00,0.00 L0.00,0.00 Z" style={{stroke: "none", fill: "#fff"}}></path>
            </svg>
        </div>
        <Container className="mt-7">
            <Row>
            <Col lg={6} xl={7}>
                <Row>
                <Col sm={4} className="navbar-dark">
                    <h5 className="mb-4 text-white">Pages</h5>
                    <Nav className="mb-0 flex-column">
                        <Link className="mb-3 text-light" to="/#features-section">Features</Link>
                        <Link className="mb-3 text-light" to="/#video-section">Video</Link>
                        <Link className="mb-3 text-light" to="/#features-section">Use Cases</Link>
                        <Link className="mb-3 text-light" to="/about-us#">About Us</Link>
                        <Link className="mb-3 text-light" to="/mission#">Mission</Link>
                        <Link className="mb-3 text-light" to="/privacy#">Privacy Policy</Link>
                    </Nav>
                </Col>
                {/*<div className="col-12 col-sm-4 mt-6 mt-sm-0 navbar-dark">
                    <h5 className="mb-4 text-white">Legal</h5>
                    <ul className="navbar-nav list-unstyled mb-0">
                    <li className="mb-3 nav-item"><a className="nav-link" href="security.html">Security</a>
                    </li>
                    <li className="mb-3 nav-item"><a className="nav-link" href="privacy-policy.html">Privacy Policy</a>
                    </li>
                    <li className="mb-3 nav-item"><a className="nav-link" href="terms-and-conditions.html">Term Of Service</a>
                    </li>
                    <li className="mb-3 nav-item"><a className="nav-link" href="privacy-for-humans.html">Privacy for Humans</a>
                    </li>
                    </ul>
                </div>*/}
                </Row>
                <Row>
                <Col sm={6}>
                    <Link className="footer-logo primary-text h2 mb-0" to="/">
                        <span className="fw-bold">Daily </span><span className="text-primary fw-bold">Stream</span>
                    </Link>
                </Col>
                <Col sm={6} className="mt-6 mt-sm-0">
                    {/*<ul className="list-inline mb-0">
                    <li className="list-inline-item"><a className="text-light ic-2x" href="#"><i className="la la-facebook"></i></a>
                    </li>
                    <li className="list-inline-item"><a className="text-light ic-2x" href="#"><i className="la la-dribbble"></i></a>
                    </li>
                    <li className="list-inline-item"><a className="text-light ic-2x" href="#"><i className="la la-instagram"></i></a>
                    </li>
                    <li className="list-inline-item"><a className="text-light ic-2x" href="#"><i className="la la-twitter"></i></a>
                    </li>
                    <li className="list-inline-item"><a className="text-light ic-2x" href="#"><i className="la la-linkedin"></i></a>
                    </li>
                    </ul>*/}
                </Col>
                </Row>
            </Col>
            </Row>
        </Container>
        </footer>
    );
}

//Exports
export default Footer
