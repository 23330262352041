//Imports
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye, faTimesCircle, faCheckCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { setCookie, getCookie } from '../../Hooks/Cookie';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import PasswordChecklist from 'react-password-checklist';
import Loader from '../../Fragments/Loader';

//Icon Styles
const styles = {
  colors: {
    primary: {
      color: "#007bff"
    }
  },
  hidden: {
    display: 'none'
  },
  visible: {
    display: 'table-cell'
  },
  font: {
    fontFamily: 'Roboto, Helvetica, sans-serif'
  },
  table: {
    maxHeight: '400px !important'
  },
  card: {
    header: {
      backgroundColor: '#727cf5',
      color: '#fff'
    }
  },
  icons: {
    connected: {
      color: '#10b759',
    },
    disconnected: {
      color: '#ff3366'
    },
    save: {
      color: '#10b759',
      fontSize: '1.2rem',
      cursor: 'pointer',
      marginRight: '10px'
    },
    cancel: {
      color: '#fbbc06',
      fontSize: '1.2rem',
      cursor: 'pointer'
    },
    edit: {
      color: '#007bff',
      fontSize: '1.2rem',
      cursor: 'pointer'
    },
    danger: {
      color: '#ff3366',
      fontSize: '1.2rem',
      cursor: 'pointer'
    }
  },
  alignMiddle: {
    alignItems: "center"
  },
  spinner: {
    height: "5rem",
    width: "5rem"
  }
}

/**
 * Daily Stream user setup
 * form for user setup page
 */
const UserSetupForm = () => {
    //States
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [queryParams, setQueryParams] = useSearchParams();
    const [showModal, setShowModal] = useState(false);
    const [userSetupData, setUserSetupData] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        hasPassword: null
    });
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [meetsRequirements, setMeetsRequirements] = useState(0);
    const [showPassword, setShowPassword] = useState(false);
    const [showPhoneVerification, setShowPhoneVerification] = useState(false);
    const [hasTexted, setHasTexted] = useState(false);
    const [text, setText] = useState(true);
    const [code, setCode] = useState(null);
    const [requirePhone, setRequirePhone] = useState(false);
    const navigate = useNavigate();
    const email = queryParams.get("email");
    const otp = queryParams.get("otp");
    const [modal, setModal] = useState({
        title: "", 
        body: "", 
        submit: false, 
        submitText: "", 
        submitEvent: null, 
        close: false, 
        closeText: "",
        hideEvent: null
    });

    //Helpers
    /**
     * Gets missing information on
     * user
     */
    const getSetupForm = () => {
        let data = {
            email: email,
            otp: otp
        }

        //Getting setup data modal
        setModal({
            title: "Setup",
            body: <>
                    <Row className="d-flex align-items-center justify-content-center text-center mb-3">
                        <Col>
                        <FontAwesomeIcon 
                            size="2x"
                            style={styles.icons.disconnected}
                            icon={faSpinner}  
                            spin
                        />
                        </Col>
                    </Row>
                    <Row className="d-flex align-items-center justify-content-center text-center mb-auto">
                        <Col>
                            <p className="mb-auto">Retrieving user setup information...</p>
                        </Col>
                    </Row>
                </>,
            submit: false,
            close: false,
            hideEvent: false
        });
        handleShowModal();

        //Send get user setup data
        fetch(`${window.apiSite}/getUserSetup`, {
          method: `POST`,
          credentials: `include`,
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data)
        })
        .then(res => res.json())
        .then(json => onGetSetupForm(json))
    }

    /**
     * Creates user setup form
     * based on the returned 
     * missing information in
     * the response
     * @param {JSON} data response data
     *  @param {Number} data.error error status of response
     *  @param {String} data.message accompanying error message
     *  @param {Array} data.nullFields all of the user's missing fields
     */
    const onGetSetupForm = (data) => {
        //Check for error
        if (!data.error) {
            //Display success message
            setModal({
                title: "Success",
                body: <>
                        <Row className="d-flex align-items-center justify-content-center text-center mb-3">
                            <Col>
                            <FontAwesomeIcon 
                                size="2x"
                                style={styles.icons.connected}
                                icon={faCheckCircle}  
                            />
                            </Col>
                        </Row>
                        <Row className="d-flex align-items-center justify-content-center text-center mb-auto">
                            <Col>
                                <p className="mb-auto">Successfully retrieved user setup information. Please fill out the information below to complete the setup process.</p>
                            </Col>
                        </Row>
                    </>,
                submit: false,
                close: false,
                hideEvent: false
            });
            handleShowModal();

            //Set all user setup data
            if(data.setup.phone === false) {
              setUserSetupData({
                firstName: data.setup.firstName,
                lastName: data.setup.lastname,
                phone: null,
                hasPassword: data.setup.hasPassword
              })
              setRequirePhone(false)
            } else {
              setUserSetupData(data.setup);
              setRequirePhone(true)
            }
        } else {
            //Display error message
            setModal({
                title: "Error",
                body: <>
                        <Row className="d-flex align-items-center justify-content-center text-center mb-3">
                            <Col>
                            <FontAwesomeIcon 
                                size="2x"
                                style={styles.icons.disconnected}
                                icon={faTimesCircle}  
                            />
                            </Col>
                        </Row>
                        <Row className="d-flex align-items-center justify-content-center text-center mb-auto">
                            <Col>
                                <p className="mb-auto">{data.message}</p>
                            </Col>
                        </Row>
                    </>,
                submit: false,
                close: false, 
                hideEvent: false
            });
            handleShowModal();

            setError(!error);
            setErrorMessage(data.message);
            handleShowErrorMessage();
        }
        setLoading(false);
    }

    /**
     * Sends setup data
     * to complete user setup
     */
    const setupUser = (e) => {
        e.preventDefault();

        let { valid, message } = validateSetup(userSetupData);
    
        //Check password requirements
        if (valid) {
            //Set data
            let data = {
                email: email,
                otp: otp,
                firstName: userSetupData.firstName,
                lastName: userSetupData.lastName,
                phone: userSetupData.phone,
                password: password,
                code: code
            }

            //Setting up user modal
            setModal({
                title: "Complete Setup",
                body: <>
                        <Row className="d-flex align-items-center justify-content-center text-center mb-3">
                            <Col>
                            <FontAwesomeIcon 
                                size="2x"
                                style={styles.icons.disconnected}
                                icon={faSpinner}  
                                spin
                            />
                            </Col>
                        </Row>
                        <Row className="d-flex align-items-center justify-content-center text-center mb-auto">
                            <Col>
                                <p className="mb-auto">Completing user setup process...</p>
                            </Col>
                        </Row>
                    </>,
                submit: false,
                close: false,
                hideEvent: false
            });
            handleShowModal();

            //Send setup data
            fetch(`${window.apiSite}/setupUser`, {
              method: `POST`,
              credentials: `include`,
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data)
            })
            .then(res => res.json())
            .then(json => onSetupUser(json))
        } else {
            //Display error with password
            setModal({
                title: "Error",
                body: <>
                        <Row className="d-flex align-items-center justify-content-center text-center mb-3">
                            <Col>
                            <FontAwesomeIcon 
                                size="2x"
                                style={styles.icons.disconnected}
                                icon={faTimesCircle}  
                            />
                            </Col>
                        </Row>
                        <Row className="d-flex align-items-center justify-content-center text-center mb-auto">
                            <Col>
                                <p className="mb-auto">{message}</p>
                            </Col>
                        </Row>
                    </>,
                submit: false,
                cancel: false,
                hideEvent: false
            });
            handleShowModal();
        }
    }

    /**
     * Handles response to submitting
     * user setup data
     * @param {JSON} data response data 
     *  @param {Number} data.error error status of response
     *  @param {String} data.message accompanying error message
     */
    const onSetupUser = (data) => {
        //Check for error
        if (!data.error) {
            //Display success message
            setModal({
                title: "Success",
                body: <>
                        <Row className="d-flex align-items-center justify-content-center text-center mb-3">
                            <Col>
                            <FontAwesomeIcon 
                                size="2x"
                                style={styles.icons.connected}
                                icon={faCheckCircle}  
                            />
                            </Col>
                        </Row>
                        <Row className="d-flex align-items-center justify-content-center text-center mb-auto">
                            <Col>
                                <p className="mb-auto">You have succesfully updated your user profile! You are now being logged in...</p>
                            </Col>
                        </Row>
                    </>,
                submit: false,
                close: false,
                hideEvent: false
            });
            handleShowModal();

            //Redirect user to app
            setTimeout(() => {
                window.location.href = `${getCookie('url')}/channels`;
            }, 2500);
        } else {
            //Show error message
            setModal({
                title: "Error", 
                body: <>
                        <Row className="d-flex align-items-center justify-content-center text-center mb-3">
                            <Col>
                            <FontAwesomeIcon 
                                size="2x"
                                style={styles.icons.disconnected}
                                icon={faTimesCircle}  
                            />
                            </Col>
                        </Row>
                        <Row className="d-flex align-items-center justify-content-center text-center mb-auto">
                            <Col>
                                <p className="mb-auto">{data.message}</p>
                            </Col>
                        </Row>
                    </>,
                submit: false,
                close: false,
                hideEvent: false
            });
            handleShowModal();
        }
    }

    /**
     * Sets first name in setup data 
     */
    const setFirstName = (firstName) => {
        setUserSetupData((prevData) => ({
            firstName: firstName,
            lastName: prevData.lastName,
            phone: prevData.phone,
            hasPassword: prevData.hasPassword
        }))
    }

    /**
     * Sets last name in setup data 
     */
    const setLastName = (lastName) => {
        setUserSetupData((prevData) => ({
            firstName: prevData.firstName,
            lastName: lastName,
            phone: prevData.phone,
            hasPassword: prevData.hasPassword
        }))
    }

    /**
     * Sets phone number in setup data 
     */
    const setPhone = (phone) => {
        setUserSetupData((prevData) => ({
            firstName: prevData.firstName,
            lastName: prevData.lastName,
            phone: phone,
            hasPassword: prevData.hasPassword
        }))
    }

    /**
     * Sends a text verification
     * code to the phone number provided
     */
    const phoneVerification = () => {
        if (!userSetupData.phone || userSetupData.phone.length != 10) {
            //Display error message
            setModal({
                title: "Error",
                body: <>
                        <Row className="d-flex align-items-center justify-content-center text-center mb-3">
                            <Col>
                            <FontAwesomeIcon 
                                size="2x"
                                style={styles.icons.disconnected}
                                icon={faTimesCircle}  
                            />
                            </Col>
                        </Row>
                        <Row className="d-flex align-items-center justify-content-center text-center mb-auto">
                            <Col>
                                <p className="mb-auto">Phone number must be 10 digits!</p>
                            </Col>
                        </Row>
                    </>,
                submit: false,
                close: false,
                hideEvent: false
            });
            handleShowModal();
        } else {
            //Update texted state and text refresh timer
            setHasTexted(true);
            setText(false);
            setShowPhoneVerification(true);
            setTimeout(() => {
                setText(true);
            }, 30000);

            //Verifying phone number modal
            setModal({
                title: "Verify Phone",
                body: <>
                        <Row className="d-flex align-items-center justify-content-center text-center mb-3">
                            <Col>
                            <FontAwesomeIcon 
                                size="2x"
                                style={styles.icons.disconnected}
                                icon={faSpinner}  
                                spin
                            />
                            </Col>
                        </Row>
                        <Row className="d-flex align-items-center justify-content-center text-center mb-auto">
                            <Col>
                                <p className="mb-auto">Sending verification code to your mobile device...</p>
                            </Col>
                        </Row>
                    </>,
                submit: false,
                close: false,
                hideEvent: false
            });
            handleShowModal();

            //Send verification code
            let data = {
                email: email,
                otp: otp,
                phone: userSetupData.phone
            }
            fetch(`${window.apiSite}/phoneVerification`, {
              method: `POST`,
              credentials: `include`,
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data)
            })
            .then(res => res.json())
            .then(json => onPhoneVerification(json))
        }
    }

    /**
     * Handles response to sending a text verification
     * code to the phone number provided
     * @param {JSON} data response data 
     *  @param {Number} data.error error status of request
     *  @param {String} data.message possible error message with request
     */
    const onPhoneVerification = (data) => {
        if (!data.error) {
            //Display success message
            setModal({
                title: "Success",
                body: <>
                        <Row className="d-flex align-items-center justify-content-center text-center mb-3">
                            <Col>
                            <FontAwesomeIcon 
                                size="2x"
                                style={styles.icons.connected}
                                icon={faCheckCircle}  
                            />
                            </Col>
                        </Row>
                        <Row className="d-flex align-items-center justify-content-center text-center mb-auto">
                            <Col>
                                <p className="mb-auto">The verification code has been sent to your number. Please enter the code you receive below. If you do not get a code, you may resend a verification code.</p>
                            </Col>
                        </Row>
                    </>,
                submit: false,
                close: false,
                hideEvent: false
            });
            handleShowModal();
        } else {
            //Display error message
            setModal({
                title: "Error",
                body: <>
                        <Row className="d-flex align-items-center justify-content-center text-center mb-3">
                            <Col>
                            <FontAwesomeIcon 
                                size="2x"
                                style={styles.icons.disconnected}
                                icon={faTimesCircle}  
                            />
                            </Col>
                        </Row>
                        <Row className="d-flex align-items-center justify-content-center text-center mb-auto">
                            <Col>
                                <p className="mb-auto">{data.message}</p>
                            </Col>
                        </Row>
                    </>,
                submit: false,
                close: false,
                hideEvent: false
            });
            handleShowModal();
        }
    }

    /**
     * Validates user setup data
     * @param {Object} setup user setup data 
     * @return {Boolean} valid
     */
    const validateSetup = (setup) => {
        //Validate first name
        if (!setup.firstName || setup.firstName.length <= 0) {
            return {valid: false, message: "First name cannot be empty!"};
        }

        //Validate last name
        if (!setup.lastName || setup.lastName.length <= 0) {
            return {valid: false, message: "Last name cannot be empty!"};
        }

        if(requirePhone) {
          //Validate phone number
          if (!setup.phone || setup.phone.length != 10) {
              return {valid: false, message: "Phone number cannot be empty and must be 10 digits!"};
          }

          //Validate phone number has been verified
          if (!hasTexted) {
              return {valid: false, message: "Phone must be verified!"};
          }

          //Validate phone verification code
          if (!code || code.length <= 0) {
              return {valid: false, message: "Verification code cannot be empty!"};
          }
        }

        //Validate password
        if (!meetsRequirements) {
            return {valid: false, message: "Password must meet the requirements!"};
        }
    
        return {valid: true, message: null};
    }

    //Error message helpers
    /**
     * Handles opening and closing error message 
     */
    const handleShowErrorMessage = () => setShowErrorMessage(!showErrorMessage);

    //Modal helpers
    /**
     * Handles opening and closing modal 
     */
    const handleShowModal = () => setShowModal(!showModal);

    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
        //Create user setup form
        getSetupForm();
    }, []);

    return loading ? 
    (
        <>
            <Loader/>
        </>
    )
    :
    (
        <>
            {error ? 
                <section id="error-section" className="m-8">
                    <Container>
                        <Row>
                            <Col>
                            <Alert variant="danger" onClose={handleShowErrorMessage} dismissible>
                                <Alert.Heading>You got an error!</Alert.Heading>
                                <hr/>
                                <p>
                                    {errorMessage}
                                </p>
                            </Alert>
                            </Col>
                        </Row>
                    </Container>
                </section>
            :
                <>
                {/* Modal Window */}
                <Modal show={showModal} onHide={handleShowModal} size="sm" dialogClassName="alert-modal">
                    <Modal.Header closeButton>
                    <Modal.Title>{modal.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {modal.body}
                    </Modal.Body>
                    {modal.submit || modal.close ?
                        <Modal.Footer>
                        { modal.submit ? 
                            <Button variant="primary" onClick={modal.submitEvent ? modal.submitEvent : handleShowModal}>{modal.submitText}</Button>
                        :
                            null
                        }
                        { modal.close ? 
                        <Button variant="danger" onClick={handleShowModal}>{modal.closeText}</Button>
                        :
                        null
                        }
                        </Modal.Footer>
                    :
                        null
                    }
                </Modal>

                <section id="user-setup" className="m-8">
                    <div className="container justify-content-center">
                    <div className="row justify-content-center align-items-center">
                        <Form onSubmit={setupUser}>
                            <Form.Group as={Col} className="mb-3">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control type="text" required id="first_name" value={userSetupData.firstName} placeholder="John" onChange={(e) => setFirstName(e.target.value)}/>
                            </Form.Group>
                            <Form.Group as={Col} className="mb-3">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text" required id="last_name" value={userSetupData.lastName} placeholder="Smith" onChange={(e) => setLastName(e.target.value)}/>
                            </Form.Group>
                            { requirePhone ?
                            <>
                              <Form.Group as={Col} className="mb-3">
                                <Form.Label>Phone</Form.Label>
                                { !hasTexted ? 
                                    <InputGroup>
                                        <Form.Control type="number" id="phone" value={userSetupData.phone} placeholder="1234567890" onChange={(e) => setPhone(e.target.value)}/>
                                            <Button tabindex="-1" variant="danger" onClick={phoneVerification}>
                                                Verify!
                                            </Button>
                                    </InputGroup>
                                    :
                                    <Form.Control type="number" id="phone" value={userSetupData.phone} placeholder="1234567890" onChange={(e) => setPhone(e.target.value)}/>
                                }
                              </Form.Group>
                              { showPhoneVerification ? 
                                <Form.Group>
                                    <Form.Label>Phone Verfication</Form.Label>
                                    <InputGroup>
                                        <Form.Control type="number" id="code" value={code} placeholder="XXXXXX" onChange={(e) => setCode(e.target.value)}/>
                                        <Button disabled={text} tabIndex="-1" variant="outline-primary" onClick={phoneVerification}>
                                            Resend
                                        </Button>
                                    </InputGroup>
                                </Form.Group>
                                :
                                null
                              }
                            </>
                            : null }
                            { !userSetupData.hasPassword ?
                                <>
                                <Form.Group as={Col} className="mb-3">
                                    <Form.Label>Password</Form.Label>
                                    <InputGroup>
                                        { showPassword ?
                                            <Form.Control id="password" type="text" value={password} onChange={(e) => setPassword(e.target.value)} required/>
                                        :
                                            <Form.Control id="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} required/>
                                        }
                                        <Button tabIndex="-1" variant="outline-secondary" onClick={() => setShowPassword(!showPassword)}>
                                        { showPassword ?
                                            <FontAwesomeIcon 
                                                size="lg"
                                                icon={faEye}  
                                            />
                                        : 
                                            <FontAwesomeIcon
                                                size="lg"
                                                icon={faEyeSlash}
                                            />
                                        }
                                        </Button>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group as={Col} className="mb-3">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <InputGroup>
                                        { showPassword ?
                                            <Form.Control id="confirm_password" type="text" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required/>
                                        :
                                            <Form.Control id="confirm_password" type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required/>
                                        }
                                        <Button tabIndex="-1" variant="outline-secondary" onClick={() => setShowPassword(!showPassword)}>
                                        { showPassword ?
                                            <FontAwesomeIcon 
                                                size="lg"
                                                icon={faEye}  
                                            />
                                        : 
                                            <FontAwesomeIcon
                                                size="lg"
                                                icon={faEyeSlash}
                                            />
                                        }
                                        </Button>
                                    </InputGroup>
                                </Form.Group>
                                <Col className="mb-3">
                                    <PasswordChecklist
                                        rules={["minLength","specialChar","number","capital","match"]}
                                        minLength={8}
                                        value={password}
                                        valueAgain={confirmPassword}
                                        onChange={(isValid) => {setMeetsRequirements(isValid)}}
                                    />
                                </Col>
                                <Col>
                                <Button className="mb-3" variant="primary" type="submit">
                                    Save Profile
                                </Button>
                                </Col>
                                </>
                            :
                            null
                        }
                        </Form>
                    </div>
                    </div>
                </section>
                </>
            }
        </>
    );
}

//Exports
export default UserSetupForm;
