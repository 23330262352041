//Imports
import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import SignUpForm from '../Components/SignUp/SignUpForm';
import { HashLink as Link } from 'react-router-hash-link';
import logo from '../Assets/images/logos/graphic.png';
import '../Assets/css/modal.css';

const NavBar = () => {
    //States
    const [showModal, setShowModal] = useState(false);
    const [modal, setModal] = useState({
      title: "", 
      body: "", 
      submit: false, 
      submitText: "", 
      submitEvent: null, 
      close: false, 
      closeText: ""
    });

    //Helpers
    /**
     * Handles opening and closing modal
     */
    const handleShowModal = () => setShowModal(!showModal);

    /**
     * Opens calendly info
     * in modal window
     */
    const openCalendly = () => {
      setModal({
        title: "Schedule a Demo!",
        body: <iframe title="Calendly Iframe" src='https://calendly.com/ryandailystream/consultation' style={{"width": "100%", "height": "100%"}}></iframe>,
        submit: false,
        close: false
      });
      handleShowModal();
    }

    /**
     * Open sign up form
     * in modal window
     */
    const openSignUp = () => {
        setModal({
            title: "Sign Up Today!",
            body: <SignUpForm/>,
            submit: false,
            close: false
        });
        handleShowModal();
    }

    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
    }, []);

    return(
      <>
        {/* Modal Window */}
        <Modal show={showModal} onHide={handleShowModal} dialogClassName="calendly-modal" contentClassName="expanded">
            <Modal.Header closeButton>
            <Modal.Title>{modal.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modal.body}</Modal.Body>
            <Modal.Footer>
            { modal.submit ? 
                <Button variant="primary" onClick={modal.submitEvent ? modal.submitEvent : handleShowModal}>{modal.submitText}</Button>
            :
                null
            }
            { modal.close ? 
            <Button variant="danger" onClick={handleShowModal}>{modal.closeText}</Button>
            :
            null
            }
            </Modal.Footer>
        </Modal>

        {/* Navbar */}
          <Navbar expand="lg" sticky="top" bg="white">
            <Container>
              <Navbar.Brand className="navbar-brand logo text-dark h2 mb-0">
                  <Link className="nav-link" to="/#">
                      <Image src={logo} width="120" height="50" className="mr-4 d-none d-sm-inline"/>
                      <span className="primary-text fw-bold">Daily </span>
                      <span className="text-primary fw-bold">Stream</span>
                  </Link>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav"/>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="m-auto">
                    <Link className="nav-link" to="/#features-section">Features</Link>
                    <Link className="nav-link" to="/#video-section">Video</Link>
                    <NavDropdown title="Transformations" id="basic-nav-dropdown">
                        <NavDropdown.Item href="/transformations/coaching">Coaching</NavDropdown.Item>
                        <NavDropdown.Item href="/transformations/compliance">Compliance</NavDropdown.Item>
                        <NavDropdown.Item href="/transformations/engagement">Engagement</NavDropdown.Item>
                        <NavDropdown.Item href="/transformations/process-improvement">Process Improvement</NavDropdown.Item>
                        <NavDropdown.Item href="/transformations/qm">Quality Management</NavDropdown.Item>
                    </NavDropdown>
                    {/*<Link className="nav-link" to="/#pricing-section">Pricing</Link>
                    <Link className="nav-link" to="#" onClick={openSignUp}>Sign Up</Link>*/}
                </Nav>
                <Nav>
                  <Link className="nav-link" to="#"><Button variant="primary" onClick={openCalendly}>SCHEDULE DEMO</Button></Link>
                  <Link className="nav-link" to="/login#"><Button variant="primary">LOGIN</Button></Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>  
      </>
    );
}

//Exports
export default NavBar;
