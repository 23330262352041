//Imports
import React, { 
  useState, 
  useEffect,
} from 'react';
import building from '../../Assets/images/about/building.jpg';

/**
 * Daily Stream about
 * section for
 * about us page
 */
const AboutSection = () => {
    //States

    //Helpers

    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
    }, []);
    
    return(
        <section className="m-8" id="about-us">
        <div className="container">
            <div className="row">
            <div className="col-lg-12 d-flex justify-content-center">
                <h1><span className="primary-text fw-bold">About</span>
                    <span className="text-primary fw-bold"> Us</span>
                </h1>
            </div>
            <div className="col-md-6 d-flex justify-content-start align-content-center">
                <img src={building} style={{"borderRadius": "5%", "maxWidth": "100%", "maxHeight": "100%"}}/>
            </div>
            <div className="col-md-6 d-flex">
                <iframe
                height="100%"
                width="100%"
                style={{"borderRadius": "5%"}} 
                loading="lazy" 
                allowFullScreen 
                src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJBd-eO9dcfYcRaSyprIcKpNE&key=AIzaSyDe2paOs5LPXUwrSi_zoElyy-eKggNbmoM">
                </iframe>
            </div>
            <div className="col-md-12 text-center pt-4">
                <h5>Daily Stream was born out of necessity. 
                    Our 20 year old fintech contact center needed help 
                    overcoming the challenges of coaching, engagement and 
                    quality management in a remote environment. We spent 18 
                    months refining our breakthrough streaming technology so 
                    that other contact centers can affordably transform their 
                    remote contact center from low visibility to high 
                    definition.</h5>
                </div>
            </div>
        </div>
        </section>
    );
}

//Exports
export default AboutSection;
