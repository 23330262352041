//Imports
import React, { 
  useState, 
  useEffect
} from 'react';
import Loader from '../Fragments/Loader';
import HeroSection from '../Components/Home/HeroSection';
import FeaturesSection from '../Components/Home/FeaturesSection';
import VideoSection from '../Components/Home/VideoSection';
import ClipSection from '../Components/Home/ClipSection';
import PricingSection from '../Components/Home/PricingSection';

/**
 * Daily Stream home
 * page component
 * @param {Object} props component props
 */
const Home = (props) => {
    //States
    const [isLoading, setIsLoading] = useState(true);

    //Helpers

    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
      setIsLoading(false);
    }, []);
    
    return isLoading ? 
    (
      <Loader/>
    )
    :
    (
      <>
        <div className="page-wrapper">
          <div className="page-content">
            <HeroSection/>
            <FeaturesSection/>
            <VideoSection/> 
            <ClipSection/>
            {/*<PricingSection/>*/}
          </div>
        </div>
      </>
    );
}

//Exports
export default Home;
