//Imports
import { 
    useState,
    useEffect 
} from 'react';
import Loader from '../Fragments/Loader';
import Policy from '../Components/Privacy/Policy';

/**
 * Daily Stream privacy
 * policy page component
 */
const Privacy = () => {
    //States
    const [isLoading, setIsLoading] = useState(true);

    //Helpers

    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
        setIsLoading(false);
    }, []);

    return isLoading ?
    (
        <Loader/>
    )
    :
    (
        <>
            <section id="privacy-policy">
                <Policy/>
            </section>
        </>
    );
}

//Exports
export default Privacy;
