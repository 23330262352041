import React, { 
  useState, 
  useEffect,
} from 'react';
import SsoErrorForm from '../Components/Error/SsoErrorForm'
import Loader from '../Fragments/Loader';

const SsoError = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      setIsLoading(false);
    }, []);
    
    return isLoading ? 
    (
      <Loader/>
    )
    :
    (
      <>
        <div className="page-wrapper">
          <div className="page-content">
           <SsoErrorForm/> 
          </div>
        </div>
      </>
    );
}

export default SsoError;
