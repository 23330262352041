//Imports
import React, {
    useEffect,
    useState
} from 'react';
import process from '../../Assets/images/transformations/process.jpg';
import SmallLoader from '../../Fragments/SmallLoader';

/**
 * Daily Stream process
 * section for process
 * page
 */
const ProcessSection = () => {
    //States
    const [imageLoading, setImageLoading] = useState(true);

    //Helpers
    /**
     * Sets image loading
     * state
     */
    const imageLoaded = () => setImageLoading(!imageLoading);

    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
    }, []);

    return(
        <>
             <section id="process-improvement" className="m-8">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-12 d-flex justify-content-center">
                        <h1><span className="primary-text">Process</span>
                            <span className="text-primary"> Improvement</span>
                        </h1>
                    </div>
                    <div className="col-md-6 d-flex justify-content-start align-content-center">
                        <img 
                            src={process} 
                            style={{borderRadius: "5%", maxWidth: "100%", maxHeight: "100%"}}
                            onLoad={imageLoaded}
                            alt="Process Improvement"
                        />
                        {imageLoading && <SmallLoader/>}
                    </div>
                    <div className="col-md-6 d-flex flex-wrap">
                    <h4>Process engineers need visibility. Distributed teams, observation bias and inefficient technology has hindered important continuous improvement initiatives. Until now. Daily Stream is a fast and easy way to see your processes and procedures in a real world, unvarnished environment. Let's save time, money and customer frustration. Specific use cases and benefits include:</h4>
                    <ul>
                        <li>Customer Journey Mapping</li>
                        <li>Off Call Efficiency</li>
                        <li>Omni Channel Efficiency</li>
                        <li>Video Recording of Entire Operation</li>
                        <li>SOP Validation</li>
                    </ul> 
                    </div>
                    </div>
                </div>
            </section>
        </>
    );
}

//Exports
export default ProcessSection;
