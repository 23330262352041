//Imports
import { 
    useState,
    useEffect 
} from 'react';

/**
 * Daily Stream privacy
 * policy security section
 * component
 */
const Security = () => {
    //States

    //Helpers

    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
    }, []);

    return(
        <>
        <h1>Security</h1>
        <p>
            We have implemented reasonable measures designed to secure your personal 
            information from accidental loss and from unauthorized access, use, 
            alteration, and disclosure. The safety and security of your information 
            also depends on you. Where We have given you (or where you have chosen) 
            a password for access to certain parts of our Platform, you are 
            responsible for keeping this password confidential. We ask you not to 
            share your password with anyone. 
        </p>
        </>
    );
}

//Exports
export default Security;
