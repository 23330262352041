//Imports
import React, {
    useEffect,
    useState
} from 'react';
import coaching from '../../Assets/images/transformations/coaching.jpg';
import Loader from '../../Fragments/Loader';
import SmallLoader from '../../Fragments/SmallLoader';

/**
 * Daily Stream coaching 
 * section for coaching page
 */
const CoachingSection = () => {
    //States
    const [imageLoading, setImageLoading] = useState(true);

    //Helpers
    /**
     * Sets image loading
     * state
     */
    const imageLoaded = () => setImageLoading(!imageLoading);

    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
    }, []);

    return(
        <>
            {/*Engagment Section*/}
            <section id="engagement" className="m-8">
                <div className="container p-5">
                    <div className="row">
                    <div className="col-lg-12 d-flex justify-content-center">
                        <h1><span className="primary-text fw-bold">Coaching</span>
                        </h1>
                    </div>
                    <div className="col-md-6 d-flex justify-content-start align-content-center">
                        <img 
                            src={coaching} 
                            onLoad={imageLoaded} 
                            style={{borderRadius: "5%", maxWidth: "75%", maxHeight: "100%"}}
                            alt="Coaching"
                        />
                        {imageLoading && <SmallLoader/>}
                    </div>
                    <div className="col-md-6 d-flex flex-wrap">
                    <h4>Remote coaching doesn't have to be time consuming and challenging. With Daily Stream, real world coaching videos with team lead narration nearly create themselves. Powerful virtual coaching techniques unique to Daily Stream include:</h4>
                    <ul>
                        <li>Real World Coaching Videos Narrated by Team Leads</li>
                        <li>Agent Created Coaching Moments for Knowledge Management</li>
                        <li>New Hire Virtual Shadowing</li>
                        <li>"Plays of the Day," Every Day, from All Agents</li>
                        <li>Video Coaching Requests from Real World Incidents</li>
                        <li>Getting Noticed is in the Agents Control</li>
                        <li>Async Micro Coaching</li>
                        <li>Video Resource Center</li>
                    </ul> 
                    </div>
                    </div>
                </div>
            </section>
        </>
    );
}

//Exports
export default CoachingSection;
