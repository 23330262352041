//Imports
import React, { 
  useState, 
  useEffect,
} from 'react';
import clipping from '../../Assets/video/clipping.mp4';

/**
 * Daily Stream clip
 * section component
 * for home page
 */
const ClipSection = () => {
    //States

    //Helpers

    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
    }, []);

    return(
        <>
            {/* Clip Section */}
            <section id="clip-section" className="pt-0 m-8">
            <div className="container">
                <div className="row align-items-center justify-content-between">
                <div className="col-12 col-lg-6 col-xl-5 order-last">
                    <div> <span className="badge badge-primary-soft p-2">
                            <i className="la la-exclamation ic-3x rotation"></i>
                        </span>
                    <h2 className="mt-3">Show it, Say it, Send it</h2>
                    <p className="lead mb-5">Your team deserves to be heard and recognized. Now they can easily clip and share their best work.</p>
                    </div>
                </div>
                <div className="col-12 col-lg-6 order-lg-1 mb-6 mb-lg-0 order-first">
                    <video autoPlay muted loop>
                    <source src={clipping} type="video/mp4"/>
                    </video>
                </div>
                </div>
            </div>
            </section>
        </>
    );
} 

//Exports
export default ClipSection;
