//Imports
import React, { 
    useEffect,
    useState
} from 'react';
import compliance from '../../Assets/images/transformations/compliance.jpg';
import SmallLoader from '../../Fragments/SmallLoader';

/**
 * Daily Stream compliance
 * section component
 * for compliance page
 */
const ComplianceSection = () => {
    //States 
    const [imageLoading, setImageLoading] = useState(true);

    //Helpers
    /**
     * Sets image loading
     * state
     */
    const imageLoaded = () => setImageLoading(!imageLoading);

    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
    }, []);

    return(
        <>
        {/* Compliance Section */}
        <section id="compliance" className="m-8">
            <div className="container">
                <div className="row">
                <div className="col-lg-12 d-flex justify-content-center">
                    <h1>
                        <span className="text-primary"> Compliance</span>
                    </h1>
                </div>
                <div className="col-md-6 d-flex justify-content-start align-content-center">
                    <img 
                        src={compliance} 
                        style={{borderRadius: "5%", maxWidth: "75%", maxHeigth: "100%"}}
                        onLoad={imageLoaded}
                        alt="Compliance"
                    />
                    {imageLoading && <SmallLoader/>}
                </div>
                <div className="col-md-6 d-flex justify-content-center">
                  <h4>WFH security without compromise. Daily Stream offers AI driven facial recognition, cell phone incident recognition, and more. Avoid compliance fines and employee data theft by securing WFH workspaces with DailyStream.</h4>
                </div>
                </div>
            </div>
          </section>
        </>
    );
}

//Exports
export default ComplianceSection;
