//Imports
import React, { 
  useState, 
  useEffect,
} from 'react';
import Loader from '../Fragments/Loader';
import TwofacForm from '../Components/Twofac/TwofacForm';

/**
 * Daily Stream twofactor
 * authentication page component
 */
const Twofac = () => {
    //States
    const [isLoading, setIsLoading] = useState(true);

    //Helpers

    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
      setIsLoading(false);
    }, []);
    
    return isLoading ? 
    (
      <Loader/>
    )
    :
    (
      <>
        <div className="page-wrapper">
          <div className="page-content">
            <TwofacForm/>
          </div>
        </div>
      </>
    );
}

//Exports
export default Twofac;
