//Imports
import { 
    useState,
    useEffect 
} from 'react';

/**
 * Daily Stream privacy
 * policy information section
 * collection component
 */
const InfoCollection = () => {
    //States

    //Helpers

    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
    }, []);

    return(
        <>
            <h1>Information Collection</h1>
            <p>We collect several types of information from and about you, including:</p>
            <ul>
                <li><p><span className="fw-bold">Information provided by you</span>, such as name, address, email address, telephone number, demographic information, biometric information, and other personally identifying information. For example, We collect information from you when you fill out a form on our website, input your information into the site, or complete a physical form;</p></li>
                <li><p><span className="fw-bold">Information provided by third parties</span> about you. As part of our services, We may also collect information from individuals requesting services for you or on your behalf, your employer in connection with your employer’s use of our site, publicly available resources, and/or marketing service providers.</p></li>
                <li><p><span className="fw-bold">Information collected automatically</span>, as you navigate our Platform. Information collected automatically may include usage details, domain address, IP addresses, internet browser, operating system, your internet connection, the equipment you use to access our Platform, and information collected through cookies.</p></li>
            </ul>
        </>
    );
}

//Exports
export default InfoCollection;
